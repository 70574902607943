<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <div class="card">
                <div class="card-header">
                  <h4 class="card-title">FACTURAS</h4>
                  <div class="card-category">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-sm-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Seleccionar empresa</mat-label>
                                    <input type="text"
                                            placeholder="Ingrese el nombre o id de la empresa"
                                            aria-label="Number"
                                            matInput
                                            (keyup)="buscarEmpresa()"
                                            [formControl]="filterControlEmpresa"
                                            [matAutocomplete]="auto"
                                            style="text-transform: uppercase;"
                                            name="empresa">
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                                        <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa" [id]="empresa.id_empresa">
                                            {{empresa.empresa}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-sm-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Buscar por RFC</mat-label>
                                    <input type="text"
                                        placeholder="Ingrese el nombre o el rfc del cliente"
                                        aria-label="Number"
                                        matInput
                                        (keyup)="buscarCliente()"
                                        [formControl]="filterControlCliente"
                                        [matAutocomplete]="autoDos"
                                        style="text-transform: uppercase;">
                                    <mat-autocomplete #autoDos="matAutocomplete" (optionSelected)="optionCliente($event)">
                                        <mat-option *ngFor="let cliente of clientes_busqueda" [value]="cliente.nombre" [id]="cliente.id_catclientes">
                                            {{cliente.nombre}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table mat-table [dataSource]="dataSource" class="w-100 h-100">
                                                                        
                        <ng-container matColumnDef="Folio">
                            <th mat-header-cell *matHeaderCellDef>Folio</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.folio }}
                            </td>
                        </ng-container> 

                        <ng-container matColumnDef="Fecha">
                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.fecha }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Cliente">
                            <th mat-header-cell *matHeaderCellDef> Cliente </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.cliente}}
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell *matHeaderCellDef> Total </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.total }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Observaciones">
                            <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.observaciones }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Estatus">
                            <th mat-header-cell *matHeaderCellDef> Estatus </th>
                            <td mat-cell *matCellDef="let element">
                                TIMBRADO
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Accion">
                            <th mat-header-cell *matHeaderCellDef> Acción </th>
                            <td mat-cell *matCellDef="let element">
                                <button [matMenuTriggerFor]="menu" class="btn btn-info btn-sm"><i class="fas fa-ellipsis-v"></i></button>
                                <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="descargar(element.id_factura, 2)">
                                    <mat-icon>cloud_download</mat-icon>
                                    <span>Descargar XML</span>
                                </button>
                                <button mat-menu-item (click)="descargar(element.id_factura, 1)">
                                    <mat-icon>picture_as_pdf</mat-icon>
                                    <span>Descargar PDF</span>
                                </button>
                                <button mat-menu-item (click)="enviarCorreo(element.id_factura)">
                                    <mat-icon>mail</mat-icon>
                                    <span>Enviar por correo</span>
                                </button>
                                </mat-menu>
                                <!-- <button class="btn btn-sm btn-yellow text-white" (click)="descargar(element.id_factura, 1)">PDF <i class="far fa-file-pdf"></i></button>
                                <button class="btn btn-sm btn-info" (click)="descargar(element.id_factura, 2)">XML <i class="fas fa-file-code"></i></button>
                                <button class="btn btn-sm btn-success" (click)="enviarCorreo(element.id_factura)"><i class="fa fa-envelope" aria-hidden="true"></i> CORREO</button>                                 -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator 
                        [pageSizeOptions]="[10, 20, 30]"
                        showFirstLastButtons 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>