<div class="header " style="height: 250px;">
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col">
      <div class="card border-info">
        <div class="card-header">
          Calculo integrados de empleados
        </div>
        <div class="card-body">
          <div class="container-fluid">


            <div class="col-sm-12 col-lg-12">
              <div class="row">
              <!-- BUSCAR EMPRESA -->
              <div class="col-6">
              <mat-form-field class="w-100">
                <mat-label>Buscar empresa</mat-label>
                <input type="text" placeholder="Ingrese el nombre o id de la empresa" aria-label="Number" matInput
                  (keyup)="buscarEmpresa()" [formControl]="filterControlEmpresa" [matAutocomplete]="auto"
                  style="text-transform: uppercase;">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                  <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa"
                    [id]="empresa.id_empresa">
                    {{empresa.empresa}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
               </div>
               <!-- REGISTRO PATRONAL -->
               <div class="col-6">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Registro patronal" [(ngModel)]="regPatronal" name="regPatronal">
                </mat-form-field>
               </div>
            </div>

            </div>
            <div class="row">
              <!-- BIMESTRE -->
              <div class="col-sm-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>BIMESTRE</mat-label>
                  <mat-select [(ngModel)]="mesActual" (selectionChange)="mesSeleccionado($event.value)">
                    <mat-option *ngFor="let messel of bimestres"
                    [value]="messel.Id">{{messel.meses}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- EJERCICIO -->
              <div class="col-sm-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>EJERCICIO</mat-label>
                  <mat-select [(ngModel)]="ejercicioActual" (selectionChange)="ejercicioSeleccionado($event)">
                    <mat-option *ngFor="let ejersel of ejercicio"
                      [value]="ejersel.ejercicio">{{ejersel.ejercicio}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- RFC TRABAJADOR -->
              <div class="col-sm-12 col-lg-6">
                <mat-form-field class="w-100">
                  <input matInput placeholder="RFC Trabajador" [(ngModel)]="rfcTrab" name="rfcTrab">
                </mat-form-field>
              </div>
              <!-- BOTONES -->
              <div class="col-lg-12 col-sm-12">
                <button (click)="traerEmpleados()" class="btn btn-success" title="Validar"><i class="fa fa-plus-circle"
                    aria-hidden="true"></i>
                  Calcular SDI</button>

                </div>
              <!-- PROCESOS -->
              <div *ngIf="loading" class="text-center mt-4 col">
                <h1 class="title" >Proceso</h1>     <span><b>RFC:</b> {{empleadoProcesado.rfc}}   <b>Nombre:</b> {{empleadoProcesado.nombre}}</span> <br>
                <span><b> {{ registrosProcesados }}</b> registros de <b>{{ totalRegistros }}</b></span>
                <mat-progress-bar class="mat-progress-bar" mode="determinate"[value]="progreso" ></mat-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


