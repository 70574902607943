export class DetalleTurno {
  constructor(
   public id_det_turno: number,
   public dia: number,
   public hr_entrada: string,
   public hr_salida: string,
   public descanso: number,
   public dia_descrip?: string
  ){}
}
