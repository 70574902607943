<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">ASIGNAR TURNO TRABAJADOR</h2>
           <!-- BUSCADOR -->
           <div  class="row">
            <div class="col-md-6 col-sm-12 d-flex align-items-center">
              <mat-form-field class="w-100 px-1">
                <mat-label class="d-flex justify-content-between align-items-center" >Buscar empleado <i class="fa fa-search" aria-hidden="true"></i></mat-label>
                <input type="text"
                 placeholder="NOMBRE o RFC"
                 aria-label="Number"
                 matInput
                 (keyup)="buscarCandidato()"
                 [formControl]="filterControl"
                [matAutocomplete]="auto" style="text-transform: uppercase;"
                >
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionUsuario($event)">
                  <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                    [id]="empleado">
                    {{empleado.nombre}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- <button class="btn btn-sm btn-danger"><i class="fa fa-search" aria-hidden="true"></i></button> -->
            </div>

          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-3">
                <mat-form-field *ngIf="turnoOk == true" >
                  <input
                  title="¿EDITAR?"
                  (click)="editarAsignados()"
                  style="cursor: pointer;"
                   matInput placeholder="Turno"
                   value={{tSel.tAsignado}}>
                </mat-form-field>
                <mat-form-field *ngIf="turnoOk == false">
                  <mat-label>Turnos</mat-label>
                  <mat-select  (selectionChange)="optionTurno($event) ">
                    <mat-option *ngFor="let turno of turnoSelect" [value]="turno.id_turno">
                      {{turno.turno}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4 col-sm-12 d-flex align-items-center">
                <mat-form-field
                class="w-100 px-1"
                *ngIf="turnoOk2 == true" >
                  <input
                  title="¿EDITAR?"
                  (click)="editarAsignados2()"
                  style="cursor: pointer;"
                   matInput placeholder="Jefe Directo"
                   value={{tSel.jAsignado}}>
                </mat-form-field>

                <mat-form-field
                *ngIf="turnoOk2 == false"
                class="w-100 px-1">
                  <mat-label class="d-flex justify-content-between align-items-center" >Jefe directo <i class="fa fa-search" aria-hidden="true"></i></mat-label>
                  <input type="text"
                   placeholder="NOMBRE o RFC"
                   aria-label="Number"
                   matInput
                   (keyup)="buscarCandidato()"
                   [formControl]="filterControl"
                  [matAutocomplete]="auto" style="text-transform: uppercase;"
                  >
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionUsuario2($event)">
                    <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                      [id]="empleado">
                      {{empleado.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <button  (click)="asignar()" class="btn btn-primary">ASIGNAR TURNO</button>
              </div>
            </div>

          </div>

        </div>
        </div>
        </div>
        </div>
      </div>
