<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
<div class="row">
    <div class="col-lg-12 col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">CONCEPTOS</h4>
            </div>
            <div class="card-body">

                
            </div>
        </div>
    </div>
</div>
</div>
