import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema_nomina',
  templateUrl: './sistema_nomina.component.html',
  styleUrls: ['./sistema_nomina.component.css']
})
export class SistemaNominaComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
