<div #Apdf>
<mat-dialog-content>
  <mat-dialog-actions align="end" style="padding-top: 0px;">
    <button *ngIf="!enPDF" mat-button class="btn btn-danger rounded-circle p-1" (click)="cerrarModal()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
  <div class="row">
    <div class="col-6">
      <h2>Detalles del Trabajador</h2>
      <p><strong>Nombre:</strong> {{ trabajador.nombre }}</p>
      <p><strong>IMSS:</strong> {{ trabajador.imss }}</p>
      <p><strong>RFC:</strong> {{ trabajador.rfc }}</p>
    </div>

    <!-- Mostrar los totales -->
    <div class="totals col-6">
      <h2>Totales</h2>
      <p><strong>Importes:</strong> {{ totalImportes | currency:'MXN':'symbol-narrow':'1.2-2' }}</p>
      <p><strong>Gravado IMSS:</strong> {{ totalGravadosIMSS | currency:'MXN':'symbol-narrow':'1.2-2' }}</p>
      <div class="row">
        <div class="col-6">
          <p><strong>Factor:</strong> {{ trabajador.factor }}</p>
        </div>
        <div class="col-6">
          <button *ngIf="!enPDF" (click)="this.exportarAPDF()" class="btn btn-outline-info btn-sm me-md-2" title="Descarga segura">
            Descargar PDF <i class="fa fa-download " aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>


<mat-dialog-content>
  <h2 class="text-center">Variables</h2>

  <table class="table">
    <thead>
      <tr>
        <th>Clave</th>
        <th>Concepto</th>
        <th>Importe</th>
        <th>Gravado IMSS</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="trabajadorV.variables && trabajadorV.variables.length > 0; else loading">
        <tr *ngFor="let variable of trabajadorV.variables">
          <td>{{ variable.clave }}</td>
          <td>{{ variable.concepto }}</td>
          <td>{{ variable.importe | currency:'MXN':'symbol-narrow':'1.2-2' }}</td>
          <td>{{ variable.gravado_imss | currency:'MXN':'symbol-narrow':'1.2-2' }}</td>
        </tr>
      </ng-container>
      <ng-template #loading>
        <tr>
          <td colspan="4">Cargando variables...</td>
        </tr>
      </ng-template>
    </tbody>
  </table>

</mat-dialog-content>
</div>
