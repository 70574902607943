<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
      <div class="col-lg-12 col-sm-12">
          <div class="card">
              <div class="card-header">
                <h4 class="card-title">EMPRESAS</h4>
                <div class="card-category">
                  <div class="row align-items-center">
                      <div class="col-lg-4 col-sm-12">
                          <mat-form-field class="w-100">
                              <mat-label>Buscar empresa</mat-label>
                              <input type="text"
                                      placeholder="Ingrese el nombre o id de la empresa"
                                      aria-label="Number"
                                      matInput
                                      (keyup)="buscarEmpresa()"
                                      [formControl]="filterControlEmpresa"
                                      [matAutocomplete]="auto"
                                      style="text-transform: uppercase;">
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                                  <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa" [id]="empresa.id_empresa">
                                      {{empresa.empresa}}
                                  </mat-option>
                              </mat-autocomplete>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                      </div>
                      <div class="col-lg-4 col-sm-12 text-right">
                          <!-- <button [matMenuTriggerFor]="menu" class="btn btn-info"><i class="fas fa-ellipsis-v"></i></button>
                          <mat-menu #menu="matMenu">
                              <button mat-menu-item>
                                  <mat-icon><i class="fas fa-file-download"></i></mat-icon>
                                  <span>Reporte de empresa</span>
                              </button>
                          </mat-menu> -->
                      </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                  <div class="row">
                      <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                          <table mat-table [dataSource]="dataSource" class="w-100">
                              
                              <ng-container matColumnDef="Id">
                                  <th mat-header-cell *matHeaderCellDef> Id </th>
                                  <td mat-cell *matCellDef="let element"> {{element.id_empresa}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="Empresa">
                                  <th mat-header-cell *matHeaderCellDef> Empresa </th>
                                  <td mat-cell *matCellDef="let element"> {{element.empresa}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="Representante">
                                  <th mat-header-cell *matHeaderCellDef> Represante Legal </th>
                                  <td mat-cell *matCellDef="let element"> {{element.repre}} </td>
                              </ng-container>
  
                              <ng-container matColumnDef="Razon">
                                <th mat-header-cell *matHeaderCellDef> Razón social </th>
                                <td mat-cell *matCellDef="let element"> {{element.razon_social}} </td>
                              </ng-container>
  
                              <ng-container matColumnDef="accion">
                                  <th mat-header-cell *matHeaderCellDef> Acción </th>
                                  <td mat-cell *matCellDef="let element">  
                                      <button [matMenuTriggerFor]="menu" class="btn btn-info btn-sm"><i class="fas fa-ellipsis-v"></i></button>
                                      <mat-menu #menu="matMenu">
                                          <button mat-menu-item (click)="visualizar(element.id_empresa)">
                                              <mat-icon><i class="far fa-eye"></i></mat-icon>
                                              <span>Ver</span>
                                          </button>
                                      </mat-menu>
                                  </td>
                              </ng-container>
      
                              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                          <mat-paginator 
                              [pageSizeOptions]="[5, 10, 20]"
                              showFirstLastButtons 
                              aria-label="Select page of periodic elements">
                          </mat-paginator>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 class="modal-title" id="tituloModal">{{info_modal.nombre_empresa}}</h2>
    </div>
    <div class="modal-body">
      <mat-tab-group mat-align-tabs="center" class="mt-2" style="height: 350px;">
        <mat-tab label="Información general">
          <div class="row w-100 mt-3">
            <div class="col-sm-12 col-lg-4" style="position: relative;">
              <img [src]="foto_user" style="object-fit: cover;
              object-position: center center;height: 120px;width: 100%;">
              <div [matMenuTriggerFor]="menu" class="img-circle d-flex align-items-center justify-content-center" style="height: 30px;width:30px;position: absolute;right:0;bottom:0;border: 1px solid gray;background-color: gray;border-radius: 50%;cursor: pointer;">
              <i class="fas fa-pencil-alt" style="color: white;"></i>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item disabled>
                  <mat-icon><i class="fas fa-camera"></i></mat-icon>
                    <span>Tomar foto</span>
                  </button>
                  <button mat-menu-item (click)="subirImagen('foto_user')">
                    <mat-icon><i class="fas fa-file-upload"></i></mat-icon>
                      <span>Subir imagen</span>
                    </button>
                </mat-menu>
                <input type="file" id="foto_user" (change)="cambiarImagen($event)" style="display: none;">
              </div>
            </div>
            <div class="col-sm-12 col-lg-8">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput [(ngModel)]="empresa.empresa" name="empresa" style="text-transform: uppercase;">
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100">
                    <mat-label>Razón social</mat-label>
                    <input matInput [(ngModel)]="empresa.razon_social" name="razon" style="text-transform: uppercase;">
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100">
                    <mat-label>RFC</mat-label>
                    <input matInput #rfc maxlength="13" [(ngModel)]="empresa.rfc" name="rfc" style="text-transform: uppercase;">
                    <mat-hint align="end">{{rfc.value.length}} / 13</mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="w-100">
                    <mat-label>Regimen fiscal</mat-label>
                    <input matInput #rfc maxlength="5" [(ngModel)]="empresa.regimen" name="regimen" style="text-transform: uppercase;">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-sm-12 col-lg-4">
              <mat-form-field class="w-100">
                <mat-label>.CER</mat-label>
                <input matInput [(ngModel)]="nombre_cer" (click)="subirImagen('cerUpload')" style="cursor: pointer;" readonly>
                <input type="file" name="" id="cerUpload" style="display: none;" accept=".cer" (change)="subirCredencial(1,$event)">
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-lg-4">
              <mat-form-field class="w-100">
                <mat-label>.KEY</mat-label>
                <input matInput [(ngModel)]="nombre_key" (click)="subirImagen('keyUpload')" style="cursor: pointer;" readonly>
                <input type="file" name="" id="keyUpload" style="display: none;" accept=".key" (change)="subirCredencial(2,$event)">
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-lg-4">
              <mat-form-field class="w-100">
                <mat-label>Contraseña</mat-label>
                <input type="password" matInput #cer maxlength="30" [(ngModel)]="empresa.firma_contra" name="cer" style="text-transform: uppercase;">
                <mat-hint align="end">{{cer.value.length}} / 20</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-lg-12 col-sm-12">
              <mat-form-field class="w-100">
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Redacte una breve descripción acerca de la empresa" [(ngModel)]="empresa.descripcion" name="desc" style="text-transform: uppercase;"></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Dirección">
          <div class="container mt-3">
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Calle</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.calle" name="calle" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Num. interior</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.numero_interior" name="ni" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Num. exterior</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.numero_exterior" name="ne" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Cruzamientos</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.cruzamiento_uno" name="cruza" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Colonia</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.colonia" name="colonia" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>C.P</mat-label>
                  <input matInput #postalCode maxlength="5" [(ngModel)]="empresa.direccion.codigo_postal">
                  <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Buscar estado</mat-label>
                  <input type="text"
                          placeholder="Ingrese del estado"
                          aria-label="Number"
                          matInput
                          (keyup)="buscarEstado()"
                          [formControl]="filterControlEstado"
                          [matAutocomplete]="autodos"
                          style="text-transform: uppercase;">
                  <mat-autocomplete #autodos="matAutocomplete" (optionSelected)="optionEstado($event)">
                      <mat-option *ngFor="let estado of estados_busqueda" [value]="estado.estado" [id]="estado.id_estado">
                          {{estado.estado}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Localidad</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.localidad" name="loca" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Municipio</mat-label>
                  <input matInput [(ngModel)]="empresa.direccion.municipio" name="muni" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Referencias</mat-label>
                  <textarea matInput placeholder="Redacte una breve referencia de la empresa" [(ngModel)]="empresa.direccion.descripcion" name="ref" style="text-transform: uppercase;"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Representante legal">
          <div class="container mt-3">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Nombre</mat-label>
                  <input matInput [(ngModel)]="empresa.representante.nombre" name="name_r" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Cargo</mat-label>
                  <input matInput #cargo [(ngModel)]="empresa.representante.cargo" name="cargo_r" style="text-transform: uppercase;" maxlength="150">
                  <mat-hint align="end">{{cargo.value.length}} / 150</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>RFC</mat-label>
                  <input matInput #rfc maxlength="13" [(ngModel)]="empresa.representante.rfc" name="repre_r" style="text-transform: uppercase;">
                  <mat-hint align="end">{{rfc.value.length}} / 13</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>CURP</mat-label>
                  <input matInput #curp maxlength="18" [(ngModel)]="empresa.representante.curp" name="repre_c" style="text-transform: uppercase;">
                  <mat-hint align="end">{{curp.value.length}} / 18</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button type="button" class="btn btn-success" (click)="guardar()">Guardar</button>
    </div>
</ng-template>
  