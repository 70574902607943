<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <!-- Toggler -->
      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
         aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Brand -->
      <a class="navbar-brand pt-0 text-center">
        <img src="./assets/img/defaults/imagen-empresa-default.png" class="navbar-brand-img" alt="..." width="150px">
        <h4>SISTEMA ADMIN. GENERAL</h4>
      </a>
      <!-- User -->
      <ul class="nav align-items-center d-md-none">
        <li class="nav-item" placement="bottom-right">
          <a class="nav-link" role="button">
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="./assets/iconos/super_admin.svg">
              </span>
            </div>
          </a>
        </li>
      </ul>
      <!-- Collapse -->
      <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a  routerLinkActive="active" [routerLink]="['/dashboard']">
                <img src="./assets/img/defaults/imagen-empresa-default.png" width="150px">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Menu -->
        <ul class="navbar-nav">
          <li *ngFor="let menuItem of menuItems" class="nav-item">
              <a *ngIf="menuItem.band == true" [attr.data-target]="'#' + menuItem.id" class="nav-link collapse.hide" [attr.data-toggle]="menuItem.tipo"  [attr.aria-expanded]="false"  [attr.aria-controls]="menuItem.id" style="cursor: pointer;" >
                  <i class="ni {{menuItem.icon}}"></i>
                  {{menuItem.title}}
              </a>
              <a *ngIf="menuItem.band == false" routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                <i class="ni {{menuItem.icon}}"></i>
                  {{menuItem.title}}
              </a>
              <div *ngIf="menuItem.band == true" class="collapse pl-4" id="{{menuItem.id}}">
                <ul class="navbar-nav" [style.background-color]="menuItem.bg">
                  <li *ngFor="let submenu of menuItem.submenu" class="nav-item">
                      <a routerLinkActive="active" [routerLink]="[submenu.path]" class="nav-link text-white">
                          <i class="ni {{submenu.icon}}"></i>
                          {{submenu.title}}
                      </a>
                  </li>
                </ul>
              </div>
          </li>
        </ul>
        <!-- Divider -->
        <hr class="my-3">
        <ul class="navbar-nav">
            <li (click)="cerrarSesion()" class="nav-item">
                <a routerLinkActive="active" class="nav-link" style="cursor: pointer;">
                    <i class="ni ni-button-power text-blue"></i>
                    Cerrar sesión
                </a>
            </li>
        </ul>
      </div>
    </div>
  </nav>
  