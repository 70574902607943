<nav class="navbar navbar-top navbar-expand-md navbar-dark {{color}}" id="navbar-main">
  <div class="container-fluid">
    <div class="prueba">
      <h2 class="text-white">{{texto}}</h2>
    </div>
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-lg-auto mt-2">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="{{url_foto}}">
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold pr-6">{{nombre}}</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bienvenido!</h6>
          </div>
          <a *ngIf="empresas.length" class="dropdown-item">
            <i class="ni ni-curved-next"></i>
            <span (click)="openModal()">Cambiar de empresa</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h4 class="modal-title" id="modal-basic-title">¿Que empresa desea administrar?</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-row">
        <div class="form-group col-md-12">
          <div class="list-group">
            <a *ngFor="let empresa of empresas" (click)="eleccion(empresa.id_empresa)" style="cursor: pointer;" class="list-group-item list-group-item-action {{empresa.class}}" aria-current="true">
              {{empresa.empresa}}
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-warning" aria-label="Close" (click)="d('Cross click')">Cancelar</button>
  </div>
</ng-template>
  
