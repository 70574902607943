<div class="{{color}}" style="height: 250px;"></div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Empresas</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[0]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                <i class="fas fa-building"></i>
              </div>
            </div>
            <!-- <p class="mt-3 mb-0 text-muted text-sm">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Facturas totales</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[1]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 3.48%</span>
            <span class="text-nowrap">Since last week</span>
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Pagadas totales</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[2]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-success text-white rounded-circle shadow">
                <i class="fas fa-thumbs-up"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
            <span class="text-nowrap">Since yesterday</span>
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Por pagar total</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[3]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                <i class="fas fa-thumbs-down"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 12%</span>
            <span class="text-nowrap">Since last month</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-12 mt-2 mx-0 py-0">
      <div class="card shadow">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col-10">
              <h3 class="mb-0">Mis empresas</h3>
            </div>
            <div class="col-2">
              <mat-form-field class="w-100">
                <mat-label>Año</mat-label>
                <mat-select [(ngModel)]="anio" (selectionChange)="mostrarDashboard()">
                  <mat-option *ngFor="let anio_t of anios" [value]="anio_t">{{anio_t}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="table-responsive"  style="height: 250px; overflow-y: scroll;">
          <!-- Projects table -->
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="text-left">Empresa</th>
                <th scope="col">Facturas</th>
                <th scope="col">Pagadas</th>
                <th scope="col">No pagadas</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let empresa of empresas" class="text-center" style="cursor: pointer;" (click)="pintarChart(empresa.id_empresa)">
                <td class="text-left">{{empresa.empresa}}</td>
                <td>{{empresa.facturas}}</td>
                <td>{{empresa.pagadas}}</td>
                <td>{{empresa.por_pagar}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2 mx-0 py-0">
      <div class="row">
        <div class="col-5">
          <div class="card shadow h-100">
            <div class="card-header bg-transparent">
                <div class="row align-items-center">
                <div class="col">
                    <h6 class="text-uppercase text-light ls-1 mb-1">Vista Previa</h6>
                    <h2 class="text-white mb-0"></h2>
                </div>
                </div>
            </div>
            <div class="card-body">
                <!-- Chart -->
                <canvas baseChart
                  [datasets]="doughnutChartData"
                  [labels]="doughnutChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  chartType="doughnut"
                  [colors]="chartColors">
                </canvas>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="card shadow">
            <div class="card-header bg-transparent">
                <div class="row align-items-center">
                <div class="col">
                    <h6 class="text-uppercase text-light ls-1 mb-1">Vista Previa</h6>
                    <h2 class="text-white mb-0"></h2>
                </div>
                </div>
            </div>
            <div class="card-body">
                <!-- Chart -->
                <canvas baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  chartType="bar"
                  [colors]="chartColors">
                </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>