<nav class="navbar navbar-top navbar-expand-md navbar-dark {{color}}" id="navbar-main">
    <div class="container-fluid">
      <!-- User -->
      <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto mt-2">
        <li class="nav-item">
          <a class="nav-link pr-0" role="button">
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="./assets/iconos/super_admin.svg">
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold pr-6">{{nombre}}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  
