<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">BITACORA DE INSIDENCIAS</h4>
          <div class="card-category">
            <!-- BUSCADOR -->
            <div *ngIf="trabajador==true " class="row">
              <div class="col-md-6 col-sm-12 d-flex align-items-center">
                <mat-form-field class="w-100 px-1">
                  <mat-label class="d-flex justify-content-between align-items-center" >Buscar empleado <i class="fa fa-search" aria-hidden="true"></i></mat-label>
                  <input type="text"
                   placeholder="NOMBRE o RFC"
                   aria-label="Number"
                   matInput
                   (keyup)="buscarCandidato()"
                   [formControl]="filterControl"
                  [matAutocomplete]="auto" style="text-transform: uppercase;"
                  >
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionUsuario($event)">
                    <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                      [id]="empleado">
                      {{empleado.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <button class="btn btn-sm btn-danger"><i class="fa fa-search" aria-hidden="true"></i></button> -->
              </div>

            </div>
            <!-- PERFIL -->
            <div *ngIf="consulta" class="row align-items-center offset-1">
              <div class="col-lg-2 col-md-6 col-sm-12 text-center">
                <img [src]="candidato.fotografia"
                  alt="Foto de perfil" class="img-fluid rounded-circle" style="width: 100px; height: 100px;">
              </div>
              <!-- <div class="col-lg-2 col-md-6 col-sm-12 text-center">
                <img src="https://icons.iconarchive.com/icons/visualpharm/must-have/256/User-icon.png"
                  alt="Foto de perfil" class="img-fluid rounded-circle" style="width: 100px; height: 100px;">
              </div> -->
              <div class="col-lg-4 col-md-6 col-sm-12 text-center">
                <h3>{{candidato.nombre}}</h3>
                <strong>{{empleadosIns[0].puesto}}</strong>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12 text-center">
                <h3>{{empleadosIns[0].turno}}</h3>
              </div>
            </div>

            <!-- TABLA -->
            <div *ngIf="consulta" class="card-body">
              <div class="row">
                <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                  <table mat-table [dataSource]="dataSource" class="w-100 custom-table">

                    <ng-container matColumnDef="dia_descrip">
                      <th mat-header-cell *matHeaderCellDef>DÍA</th>
                      <td mat-cell *matCellDef="let element"> {{element.dia_descrip}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef>FECHA</th>
                      <td mat-cell *matCellDef="let element"> {{element.fecha | date: 'dd/MM/yyyy' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="hr_entrada">
                      <th mat-header-cell *matHeaderCellDef>ENTRADA</th>
                      <td mat-cell *matCellDef="let element"> {{element.hr_entrada}} </td>
                    </ng-container>

                    <ng-container matColumnDef="hr_salida">
                      <th mat-header-cell *matHeaderCellDef>SALIDA</th>
                      <td mat-cell *matCellDef="let element"> {{element.hr_salida}} </td>
                    </ng-container>

                    <ng-container matColumnDef="falta">
                      <th mat-header-cell *matHeaderCellDef> FAL </th>
                      <td mat-cell *matCellDef="let element">
                        <i *ngIf="element.falta == '1'" class="fa fa-check text-danger"></i>
                        <i *ngIf="element.falta == '0'" class=""></i>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="dia_festivo">
                      <th mat-header-cell *matHeaderCellDef> D F </th>
                      <td mat-cell *matCellDef="let element">
                        <i *ngIf="element.dia_festivo == '1'" class="fa fa-check text-danger"></i>
                        <i *ngIf="element.dia_festivo == '0'" class=""></i>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="vacaciones">
                      <th mat-header-cell *matHeaderCellDef> VAC </th>
                      <td mat-cell *matCellDef="let element">
                        <i *ngIf="element.vacaciones == '1'" class="fa fa-check text-danger"></i>
                        <i *ngIf="element.vacaciones == '0'" class=""></i>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="retardos">
                      <th mat-header-cell *matHeaderCellDef> RET </th>
                      <td mat-cell *matCellDef="let element">
                        <i *ngIf="element.retardos == '1'" class="fa fa-check text-danger"></i>
                        <i *ngIf="element.retardos == '0'" class=""></i>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="observaciones">
                      <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
                      <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" title="Agregar Justiciación" (click)="openModal(row)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</div>


<!-- MODAL -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body" style="overflow-x: hidden;" >
    <div class="">
      <div class="col text-center">
        <h2>Agregar justificación</h2>
      </div>
      <div class="col">
        <h4>{{candidato.nombre}}</h4>
        <div class="row">
            <h4 class="ml-3 mr-1">Fecha:{{justi.fecha}}</h4><span>{{justi.dia_descrip}}</span>
          </div>
          <mat-form-field class="example-full-width">
            <input
              name= "motivo"
              autocomplete="none"
             [(ngModel)]="justi.motivo"
             matInput placeholder="Justificación"
              >
          </mat-form-field>

      </div>

      <!-- Botón para cerrar el modal -->
      <div class="col d-flex justify-content-end m-2">
        <button class="btn btn-secondary" (click)="closeModal()">Cerrar</button>
        <button class="btn btn-primary" (click)="guardarJustificacion()">Guardar</button>
      </div>
    </div>
  </div>
</ng-template>

