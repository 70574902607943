<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow" style="height: 550px;">
          <div class="card-header border-0">
              <div class="row align-items-center">
                  <div class="col">
                  <h3 class="mb-0">CATÁLOGO DE SUCURSALES</h3>
              </div>
          </div>
          <div class="card-body">
              <div class="row mb-2">
                  <div class="col-lg-4 col-sm-12 mb-2">
                      <div class="buscador">
                          <mat-form-field class="w-100">
                              <mat-label>Buscar sucursal</mat-label>
                              <input #input
                                     type="text"
                                     placeholder="Buscar..."
                                     (keyup)="busqueda(input.value)"
                                     aria-label="Buscar concepto"
                                     matInput
                                     [formControl]="myControl"
                                     [matAutocomplete]="auto"
                                     style="text-transform: uppercase;">
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getSucursal($event)'>
                                <mat-option *ngFor="let option of sucursales_bus" [value]="option.sucursal" [id]="option.id_sucursal+''">
                                  {{option.sucursal}}
                                </mat-option>
                              </mat-autocomplete>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="status" (selectionChange)="mostrarSucursales()">
                              <mat-option [value]="2">Todos</mat-option>
                              <mat-option [value]="1">Activos</mat-option>
                              <mat-option [value]="0">Inactivos</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="col-lg-4 col-sm-12" style="text-align: end;">
                      <a class="btn btn-info" (click)="guardar()" title="Nueva sucursal"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                  </div>
              </div>
              <div class="table-responsive">
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th scope="col" class="text-center">No. sucursal</th>
                              <th scope="col">Sucursal</th>
                              <th scope="col" class="text-center">Cliente</th>
                              <th scope="col" class="text-center">Zona</th>
                              <th scope="col" class="text-center">Acciones</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="sucursales.length > 0; else pintaVacio" class="">
                          <tr *ngFor="let sucursal of sucursales">
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{sucursal.id_sucursal}}</td>
                              <td colspan="1" style="vertical-align: middle;">{{sucursal.sucursal}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{sucursal.cliente}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{sucursal.zona}}</td>
                              <td colspan="1" style="width: 20%;" class="text-center">
                                  <a title="Editar" (click)="editar(sucursal.id_sucursal)" style="cursor: pointer;" class="btn btn-yellow btn-sm"><img src="./assets/iconos/edit.svg" style="width: 14px;"></a>
                              </td>
                          </tr>
                      </tbody>
                      <ng-template #pintaVacio>
                          <tbody>
                              <tr>
                                  <td colspan="5" class="text-center">No existen sucursales</td>
                              </tr>
                          </tbody>
                      </ng-template>
                  </table>
                  <div class="filtros container mt-3">
                      <div class="row">
                          <div class="col-lg-5 col-sm-12"></div>
                          <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                                <!-- <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                    <ul class="pagination">
                                    <li class="page-item" *ngIf="previous">
                                        <a class="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                    <li class="page-item" *ngIf="next">
                                        <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Next</span>
                                        </a>
                                    </li>
                                    </ul>
                                </nav> -->
                          </div>
                          <div class="col-lg-2 col-sm-12" style="text-align: end;">
                              <mat-form-field class="w-100">
                                  <mat-label>No. resultados</mat-label>
                                  <mat-select [(ngModel)]="taken" (selectionChange)="mostrarSucursales()">
                                      <mat-option [value]="5">5</mat-option>
                                      <mat-option [value]="10">10</mat-option>
                                      <mat-option [value]="20">20</mat-option>
                                      <mat-option [value]="30">30</mat-option>
                                      <mat-option [value]="40">40</mat-option>
                                      <mat-option [value]="50">50</mat-option>
                                      <mat-option [value]="100">100</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 *ngIf="tipo_modal == 1" class="modal-title" id="tituloModal">Nueva sucursal</h2>
        <h2 *ngIf="tipo_modal == 2" class="modal-title" id="tituloModal">Editar sucursal</h2>
    </div>
    <div class="modal-body">
        <div class="form">
            <div class="form-row">
                <div class="form-group col-lg-5 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Clientes</mat-label>
                        <mat-select [(ngModel)]="json.id_cliente">
                            <mat-option *ngFor="let cliente of clientes" [value]="cliente.id_cliente">{{cliente.cliente}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-2 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Zona</mat-label>
                        <mat-select [(ngModel)]="json.zona">
                            <mat-option value="A">Zona A</mat-option>   
                            <mat-option value="B">Zona B</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-2 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Región</mat-label>
                        <mat-select [(ngModel)]="json.region">
                            <mat-option value="Norte">Región Norte</mat-option>   
                            <mat-option value="Oriente">Región Oriente</mat-option>
                            <mat-option value="Poniente">Región Poniente</mat-option>
                            <mat-option value="Sur">Región Sur</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-3 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Estado</mat-label>
                        <mat-select [(ngModel)]="json.estado">
                            <mat-option *ngFor="let estado of estados" [value]="estado.id_estado">{{estado.estado}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-3 col-sm-12">
                    <label for="sucursal">Sucursal</label>
                    <input [(ngModel)]="json.sucursal" type="text" class="form-control text-uppercase" id="sucursal">
                </div>
                <div class="form-group col-lg-3 col-sm-12">
                    <label for="impuesto_estatal">Impuesto estatal</label>
                    <input [(ngModel)]="json.tasa_estatal" type="number" class="form-control text-uppercase" id="impuesto_estatal">
                </div>
                <div class="form-group col-lg-3 col-sm-12">
                    <label for="impuesto_especial">Impuesto especial</label>
                    <input [(ngModel)]="json.tasa_especial" type="number" class="form-control text-uppercase" id="impuesto_especial">
                </div>
                <div class="form-group col-lg-3 col-sm-12">
                    <label for="prima_riesgo">Prima de riesgo</label>
                    <input [(ngModel)]="json.prima_riesgo" type="number" class="form-control text-uppercase" id="prima_riesgo">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_modal == 1" type="button" class="btn btn-success" (click)="altaSucursal()" id="guardar">Guardar</button>
        <button *ngIf="tipo_modal == 2" type="button" class="btn btn-success" (click)="modificarSucursal()" id="guardar">Guardar</button>
        <!-- <button type="button" class="btn btn-success" (click)="modificarUsuario()" id="editar" >Editar</button> -->
    </div>
</ng-template>