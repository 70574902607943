<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow" style="height: 550px;">
          <div class="card-header border-0">
              <div class="row align-items-center">
                  <div class="col">
                  <h3 class="mb-0">SOLICITUDES DE R.H</h3>
              </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
                <div class="col-lg-4 col-sm-12 mb-2">
                    <div class="buscador">
                        <mat-form-field class="w-100">
                            <mat-label>Buscar solicitud</mat-label>
                            <input #input
                                   type="text"
                                   placeholder="Buscar..."
                                   (keyup)="busqueda(input.value)"
                                   aria-label="Buscar solicitud"
                                   matInput
                                   [formControl]="myControl"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getSolicitud($event)'>
                              <!-- <mat-option *ngFor="let option of departamentos_busqueda" [value]="option.departamento" [id]="option.id_departamento+''">
                                {{option.departamento}}
                              </mat-option> -->
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Filtrar por fechas</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Día inicial (mm/dd/yyyy)" [(ngModel)]="fecha_inicial">
                          <input matEndDate placeholder="Día final (mm/dd/yyyy)" [(ngModel)]="fecha_final" (dateChange)="mostrarSolicitudes()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">No. movimiento</th>
                            <th scope="col">Cliente</th>
                            <th scope="col" class="text-center">Tipo de movimiento</th>
                            <th scope="col" class="text-center">Fecha de movimiento</th>
                            <th scope="col" class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="solicitudes.length > 0; else pintaVacio" class="">
                        <tr *ngFor="let solicitud of solicitudes">
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{solicitud.id_movimiento}}</td>
                            <td colspan="1" style="vertical-align: middle;">{{solicitud.cliente}}</td>
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{solicitud.tipo_movimiento}}</td>
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{solicitud.fecha_movimiento}}</td>
                            <td colspan="1" style="width: 20%;" class="text-center">
                                <a title="Ver solicitud" style="cursor: pointer;" class="btn btn-success btn-sm" (click)="verSolicitud(solicitud.id_movimiento,solicitud.tipo_movimiento)"><img src="./assets/iconos/ver.svg" style="width: 14px;"></a>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #pintaVacio>
                        <tbody>
                            <tr>
                                <td colspan="5" class="text-center">No existen solicitudes</td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
                <div class="filtros container mt-3">
                    <div class="row">
                        <div class="col-lg-5 col-sm-12"></div>
                        <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                              <!-- <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                  <ul class="pagination">
                                  <li class="page-item" *ngIf="previous">
                                      <a class="page-link" href="#" aria-label="Previous">
                                      <span aria-hidden="true">&laquo;</span>
                                      <span class="sr-only">Previous</span>
                                      </a>
                                  </li>
                                  <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                  <li class="page-item" *ngIf="next">
                                      <a class="page-link" href="#" aria-label="Next">
                                      <span aria-hidden="true">&raquo;</span>
                                      <span class="sr-only">Next</span>
                                      </a>
                                  </li>
                                  </ul>
                              </nav> -->
                        </div>
                        <div class="col-lg-2 col-sm-12" style="text-align: end;">
                            <mat-form-field class="w-100">
                                <mat-label>No. resultados</mat-label>
                                <mat-select [(ngModel)]="taken" (selectionChange)="mostrarSolicitudes()">
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="20">20</mat-option>
                                    <mat-option [value]="30">30</mat-option>
                                    <mat-option [value]="40">40</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h2 *ngIf="tipo_titulo == 1" class="modal-title" id="tituloModal">Visualizar solicitud de contratación</h2>
        <h2 *ngIf="tipo_titulo == 2" class="modal-title" id="tituloModal">Visualizar solicitud de modificación</h2>
        <h2 *ngIf="tipo_titulo == 3" class="modal-title" id="tituloModal">Visualizar solicitud de baja</h2>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table *ngIf="tipo_titulo == 1" class="table">
            <thead>
                <tr>
                    <th colspan="1">Empleado</th>
                    <th colspan="1">Empresa</th>
                    <th colspan="1">Departamento</th>
                    <th colspan="1">Puesto</th>
                    <th colspan="1">Tipo de nómina</th>
                    <th colspan="1">Salario</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alta of table_body">
                    <td>{{alta.nombre}}</td>
                    <td>{{alta.empresa}}</td>
                    <td>{{alta.departamento}}</td>
                    <td>{{alta.puesto}}</td>
                    <td>{{alta.nomina}}</td>
                    <td>{{alta.sueldo}}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="tipo_titulo == 2" class="table">
            <thead>
                <tr>
                    <th colspan="1">Empleado</th>
                    <th colspan="1">Empresa</th>
                    <th colspan="1">Departamento</th>
                    <th colspan="1">Puesto</th>
                    <th colspan="1">Tipo de nómina</th>
                    <th colspan="1">Salario</th>
                    <th colspan="1">Observación</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of table_body">
                    <td>{{row.nombre}}</td>
                    <td>{{row.empresa}}</td>
                    <td>{{row.departamento}}</td>
                    <td>{{row.puesto}}</td>
                    <td>{{row.empresa}}</td>
                    <td>{{row.sueldo}}</td>
                    <td>{{row.observacion}}</td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="tipo_titulo == 3" class="table">
            <thead>
              <tr>
                <th colspan="1">Empleado</th>
                <th colspan="1">Fecha del movimeinto</th>
                <th colspan="1">Observacion</th>
              </tr>
            </thead>
            <tbody>
                
            </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_titulo == 1" type="button" class="btn btn-success" id="guardar" (click)="aplicarSolicitud()">Aplicar solicitud</button>
    </div>
  </ng-template>
