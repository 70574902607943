import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema-contabilidad',
  templateUrl: './sistema_contabilidad.component.html',
  styleUrls: ['./sistema_contabilidad.component.css']
})
export class SistemaContabilidadComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}