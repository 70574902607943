import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procedimiento-cierre',
  templateUrl: './procedimiento_cierre.component.html',
  styleUrls: ['./procedimiento_cierre.component.css']
})
export class ProcedimientoCierreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
