<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow" style="height: 550px;">
          <div class="card-header border-0">
              <div class="row align-items-center">
                  <div class="col">
                  <h3 class="mb-0">CATÁLOGO DE CONCEPTOS</h3>
              </div>
          </div>
          <div class="card-body">
              <div class="row mb-2">
                  <div class="col-lg-4 col-sm-12 mb-2">
                      <div class="buscador">
                          <mat-form-field class="w-100">
                              <mat-label>Buscar concepto</mat-label>
                              <input #input
                                     type="text"
                                     placeholder="Buscar..."
                                     (keyup)="busqueda(input.value)"
                                     aria-label="Buscar concepto"
                                     matInput
                                     [formControl]="myControl"
                                     [matAutocomplete]="auto">
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getConcepto($event)'>
                                <mat-option *ngFor="let concepto of conceptos_busqueda" [value]="concepto.concepto" [id]="concepto.id_concepto">
                                  {{concepto.concepto}}
                                </mat-option>
                              </mat-autocomplete>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-12">
                      <!-- <mat-form-field class="w-100">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="status" (selectionChange)="mostrarConceptos()">
                              <mat-option [value]="2">Todos</mat-option>
                              <mat-option [value]="1">Activos</mat-option>
                              <mat-option [value]="0">Inactivos</mat-option>
                          </mat-select>
                      </mat-form-field> -->
                  </div>
                  <div class="col-lg-4 col-sm-12" style="text-align: end;">
                      <a class="btn btn-info" (click)="guardar()" title="Nuevo concepto"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                  </div>
              </div>
              <div class="table-responsive">
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th scope="col" class="text-center">No. concepto</th>
                              <th scope="col">Concepto</th>
                              <th scope="col" class="text-center">Acciones</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="conceptos.length > 0; else pintaVacio" class="">
                          <tr *ngFor="let concepto of conceptos">
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{concepto.id_concepto}}</td>
                              <td colspan="1" style="vertical-align: middle;">{{concepto.concepto}}</td>
                              <td colspan="1" style="width: 20%;" class="text-center">
                                  <a title="Editar" (click)="editar(concepto.id_concepto)" style="cursor: pointer;" class="btn btn-yellow btn-sm"><img src="./assets/iconos/edit.svg" style="width: 14px;"></a>
                                  <a title="Eliminar" (click)="eliminar(concepto.id_concepto)" style="cursor: pointer;" class="btn btn-danger btn-sm"><img src="./assets/iconos/delete.svg" style="width: 14px;"></a>
                              </td>
                          </tr>
                      </tbody>
                      <ng-template #pintaVacio>
                          <tbody>
                              <tr>
                                  <td colspan="4" class="text-center">No existen conceptos</td>
                              </tr>
                          </tbody>
                      </ng-template>
                  </table>
                  <div class="filtros container mt-3">
                      <div class="row">
                          <div class="col-lg-5 col-sm-12"></div>
                          <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                                <!-- <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                    <ul class="pagination">
                                    <li class="page-item" *ngIf="previous">
                                        <a class="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                    <li class="page-item" *ngIf="next">
                                        <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Next</span>
                                        </a>
                                    </li>
                                    </ul>
                                </nav> -->
                          </div>
                          <div class="col-lg-2 col-sm-12" style="text-align: end;">
                              <mat-form-field class="w-100">
                                  <mat-label>No. resultados</mat-label>
                                  <mat-select [(ngModel)]="taken" (selectionChange)="mostrarConceptos()">
                                      <mat-option [value]="5">5</mat-option>
                                      <mat-option [value]="10">10</mat-option>
                                      <mat-option [value]="20">20</mat-option>
                                      <mat-option [value]="30">30</mat-option>
                                      <mat-option [value]="40">40</mat-option>
                                      <mat-option [value]="50">50</mat-option>
                                      <mat-option [value]="100">100</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h2 *ngIf="tipo_modal == 1" class="modal-title" id="tituloModal">Nuevo concepto</h2>
        <h2 *ngIf="tipo_modal == 2" class="modal-title" id="tituloModal">Editar concepto</h2>
    </div>
    <div class="modal-body">
        <div class="form">
            <div class="form-row">
                <div class="form-group col-12">
                    <h3 class="font-weight-bold">Datos del concepto</h3>
                </div>  
                <div class="tab-pane active col-12" id="conceptos" role="tabpanel">
                    <div class="container">
                        <div class="row">
                            <div class="form-group col-lg-6 col-sm-12">
                                <label for="concepto">Concepto</label>
                                <input type="text" [(ngModel)]="concepto.concepto" class="form-control" placeholder="Ingresa el nombre del concepto" style="text-transform: uppercase;">
                            </div>
                            <div class="form-group col-lg-6 col-sm-12">
                                <label for="tipo_concepto">Tipo de concepto</label>
                                <mat-form-field class="w-100" id="tipo_concepto">
                                    <mat-select [(ngModel)]="concepto.tipo_concepto">
                                        <mat-option *ngFor="let cat_concepto of cat_conceptos" [value]="cat_concepto.id_tipoconceptos">{{cat_concepto.tipo_concepto}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-lg-3 col-sm-12">
                                <label for="tipo">Tipo</label>
                                <mat-form-field class="w-100" id="tipo">
                                    <mat-select [(ngModel)]="concepto.tipo" (selectionChange)="mostrarTipos($event)">
                                        <mat-option value="1">Percepción</mat-option>
                                        <mat-option value="2">Deducción</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-lg-9 col-sm-12">
                                <label for="tipo_concepto_sat">Concepto SAT</label>
                                <mat-form-field class="w-100">
                                    <mat-label>Buscar concepto</mat-label>
                                    <input #input
                                        type="text"
                                        placeholder="Buscar por nombre de concepto"
                                        (keyup)="busquedaConcepto(input.value)"
                                        aria-label="Buscar concepto"
                                        matInput
                                        [formControl]="myControlConcepto"
                                        [matAutocomplete]="autoConcepto"
                                        [readonly]="band_sat"
                                        style="text-transform: uppercase;">
                                    <mat-autocomplete #autoConcepto="matAutocomplete" (optionSelected)='setTipoConcepto($event)'>
                                        <mat-option *ngFor="let option of conceptos_sat" [value]="option.conceptosat" [id]="option.id_conceptosat+''">
                                        {{option.conceptosat}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-lg-3 col-sm-6">
                                <label for="parametro_uno" class="label">Parametro 1</label>
                                <input type="number" class="form-control" [(ngModel)]="concepto.parametro_uno" id="parametro_uno">
                            </div>
                            <div class="form-group col-lg-3 col-sm-6">
                                <label for="parametro_dos" class="label">Parametro 2</label>
                                <input type="number" class="form-control" [(ngModel)]="concepto.parametro_dos" id="parametro_dos">
                            </div>
                            <div class="form-group col-lg-6 col-sm-12">
                                <label for="cuenta_contable" class="label">Cuenta contable</label>
                                <input type="text" class="form-control" [(ngModel)]="concepto.cuenta_contable" id="cuenta_contable">
                            </div>
                            <div class="form-group col-lg-3 col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.especie" name="activo" type="checkbox" class="custom-control-input" id="especie">
                                    <label class="custom-control-label" for="especie">En especie</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.automatico" name="activo" type="checkbox" class="custom-control-input" id="automatico">
                                    <label class="custom-control-label" for="automatico">Automatico</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.imprime" name="activo" type="checkbox" class="custom-control-input" id="imprime">
                                    <label class="custom-control-label" for="imprime">Imprimir</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-3 col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.impuesto_estatal" name="activo" type="checkbox" class="custom-control-input" id="impuesto_estatal">
                                    <label class="custom-control-label" for="impuesto_estatal">Impuesto estatal</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-12">
                    <h3 class="font-weight-bold">Configuración de captura</h3>
                </div>  
                <div class="tab-pane col-12" id="config" role="tabpanel">
                    <div class="container col-12">
                        <div class="row">
                            <div class="form-group col-lg-4 col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.unidades" name="activo" type="checkbox" class="custom-control-input" id="unidades">
                                    <label class="custom-control-label" for="unidades">Unidades</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-sm-6">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.importe" name="activo" type="checkbox" class="custom-control-input" id="customSwitch2">
                                    <label class="custom-control-label" for="customSwitch2">Importe</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-sm-12">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.saldo" name="activo" type="checkbox" class="custom-control-input" id="saldo">
                                    <label class="custom-control-label" for="saldo">Saldo</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-sm-12">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.imprimir" name="activo" type="checkbox" class="custom-control-input" id="imprimir" [disabled]="!concepto.saldo">
                                    <label class="custom-control-label" for="imprimir">Imprimir saldo</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-sm-12">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.incrementable" name="activo" type="checkbox" class="custom-control-input" id="incrementable" [disabled]="!concepto.saldo">
                                    <label class="custom-control-label" for="incrementable">Incrementable</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-sm-12">
                                <div class="custom-control custom-switch">
                                    <input [(ngModel)]="concepto.incrementable" name="activo" type="checkbox" class="custom-control-input" id="decrementable" [disabled]="!concepto.saldo">
                                    <label class="custom-control-label" for="decrementable">Decrementable</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <!-- <div class="form-row">
                <div class="form-group col-lg-2 col-sm-6">
                    <label for="parametro_uno" class="label">Parametro 1</label>
                    <input type="text" class="form-control" [(ngModel)]="concepto.parametro_uno" id="parametro_uno">
                </div>
                <div class="form-group col-lg-2 col-sm-6">
                    <label for="parametro_dos" class="label">Parametro 2</label>
                    <input type="text" class="form-control" [(ngModel)]="concepto.parametro_dos" id="parametro_dos">
                </div>
                <div class="form-group col-lg-3 col-sm-12">
                    <label for="cuenta_contable" class="label">Cuenta contable</label>
                    <input type="text" class="form-control" [(ngModel)]="concepto.cuenta_contable" id="cuenta_contable">
                </div>
                <div class="form-group col-lg-2 col-sm-6" style="display:flex; justify-content: center;align-items: center;">
                    <div class="form-check">
                        <input class="form-check-input" [(ngModel)]="concepto.impuesto_estatal" type="checkbox" id="impuesto_estatal">
                        <label class="form-check-label" for="impuesto_estatal">
                          Impuesto estatal
                        </label>
                    </div>
                </div>
                <div class="form-group col-lg-2 col-sm-6" style="display:flex; justify-content: center;align-items: center;">
                    <div class="form-check">
                        <input class="form-check-input" [(ngModel)]="concepto.especie" type="checkbox" id="especie">
                        <label class="form-check-label" for="especie">
                          Especie
                        </label>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_modal == 1" class="btn btn-success" (click)="guardarConcepto()">Guardar</button>
        <button *ngIf="tipo_modal == 2" class="btn btn-success" (click)="editarConcepto()">Guardar</button>
    </div>
  </ng-template>