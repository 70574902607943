<div class="header {{color}}" style="height: 250px;">
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
        <div class="card">
            <div class="card-header">
              <h4 class="card-title">CANDIDATOS</h4>
              <div class="card-category">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Buscar candidato</mat-label>
                            <input type="text"
                                    placeholder="Busque mediante el nombre o descripción"
                                    aria-label="Number"
                                    matInput
                                    (keyup)="buscarCandidato()"
                                    [formControl]="filterControl"
                                    [matAutocomplete]="auto"
                                    style="text-transform: uppercase;">
                            <mat-autocomplete #auto="matAutocomplete"></mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="status" (selectionChange)="mostrarCandidatos()">
                            <mat-option [value]="-1">Todos</mat-option>
                            <mat-option [value]="6">En reclutamiento</mat-option>
                            <mat-option [value]="5">Solicitud</mat-option>
                            <mat-option [value]="1">Activos</mat-option>
                            <mat-option [value]="3">Suspendidos</mat-option>
                            <mat-option [value]="2">De baja</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-sm-12 text-right">
                        <button [matMenuTriggerFor]="menu" class="btn btn-info"><i class="fas fa-ellipsis-v"></i></button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="guardar()">
                                <mat-icon><i class="fas fa-plus"></i></mat-icon>
                                <span>Nuevo candidato</span>
                            </button>
                            <button mat-menu-item>
                                <mat-icon><i class="fas fa-file-download"></i></mat-icon>
                                <span>Reporte de candidatos</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
              </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                        <table mat-table [dataSource]="dataSource" class="w-100">

                            <ng-container matColumnDef="id_candidato">
                                <th mat-header-cell *matHeaderCellDef> Id </th>
                                <td mat-cell *matCellDef="let element"> {{element.id_candidato}} </td>
                            </ng-container>

                            <ng-container matColumnDef="fotografia">
                                <th mat-header-cell *matHeaderCellDef> Fotografía </th>
                                <td mat-cell *matCellDef="let element">
                                  <img src="{{element.fotografia}}" alt="" height="50" width="50" style="object-fit: cover;
                                    object-position: center center;">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="candidato">
                              <th mat-header-cell *matHeaderCellDef> Candidato </th>
                              <td mat-cell *matCellDef="let element"> {{element.nombre_completo}} </td>
                            </ng-container>

                            <ng-container matColumnDef="descripcion">
                              <th mat-header-cell *matHeaderCellDef> Descripción </th>
                              <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
                            </ng-container>

                            <ng-container matColumnDef="estatus">
                                <th mat-header-cell *matHeaderCellDef> Estatus </th>
                                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                            </ng-container>

                            <ng-container matColumnDef="accion">
                                <th mat-header-cell *matHeaderCellDef> Acción </th>
                                <td mat-cell *matCellDef="let element">
                                    <button [matMenuTriggerFor]="menu" class="btn btn-info btn-sm"><i class="fas fa-ellipsis-v"></i></button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editar(element.id_candidato)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Editar</span>
                                        </button>
                                        <button *ngIf="element.id_statu == '1'" mat-menu-item (click)="descargarContrato(element.id_candidato)">
                                          <mat-icon><i class="fas fa-file-contract"></i></mat-icon>
                                          <span>Descargar contrato</span>
                                      </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator
                            [pageSizeOptions]="[5, 10, 20]"
                            showFirstLastButtons
                            aria-label="Select page of periodic elements">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 *ngIf="tipo_modal == 1" class="modal-title" id="tituloModal">Nuevo candidato</h2>
        <h2 *ngIf="tipo_modal == 2" class="modal-title" id="tituloModal">Visualizar candidato</h2>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-12">
              <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#empresa_tab" role="tab" aria-selected="true">Datos personales</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " data-toggle="tab" href="#direccion_tab" role="tab" aria-selected="false">Dirección</a>
                </li>
                <li class="nav-item" *ngIf="bandera_activo">
                  <a class="nav-link" data-toggle="tab" href="#movientos_tab" role="tab" aria-selected="false">Detalles contratación</a>
                </li>
              </ul>
            </div>
            <div class="tab-content col-12" id="myTabContent">
                <div class="tab-pane active col-12" id="empresa_tab" role="tabpanel" style="height: 450px;overflow-y: auto;">
                    <div class="container col-12">
                        <div class="row">
                            <div class="col-lg-2 col-sm-12">
                                <div class="form-group rounded hover_img_user" style="max-width:190px;height:200px;cursor: pointer;border:1px lightgray solid;display: flex;justify-content: center;align-items: center;overflow: hidden;" (mouseover)="mostrarPersiana()" (mouseout)="ocultarPersiana()">
                                  <div [class]="band_persiana ? 'persiana' : 'persiana_mostrar'">
                                      <div class="container">
                                          <div class="row mt-5 text-center justify-content-center">
                                              <a style="cursor: pointer;" class="text-info tomar_foto" (click)="openModalCamera()">Tomar fotografía</a>
                                          </div>
                                          <div class="row mt-3 justify-content-center">
                                              <a class="text-info">ó</a>
                                          </div>
                                          <div class="row text-center mt-3 justify-content-center">
                                              <a style="cursor: pointer;" class="text-info tomar_foto" (click)="subirImagen()">Subir imagen</a>
                                          </div>
                                      </div>
                                  </div>
                                  <img id="imagen_perfil" class="img-fluid" [src]="foto_user">
                                  <input type="file" #file_input id="foto_user" style="display: none;" accept=".png,.jpg" (change)="cambiarImagen($event)">
                              </div>
                            </div>
                            <div class="col-lg-10 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12">
                                        <div class="form-group">
                                            <label for="apellido_paterno">Apellido paterno *</label>
                                            <input [(ngModel)]="candidato.apellido_paterno" name="apellido_paterno" type="text" class="form-control" id="apellido_paterno" style="text-transform: uppercase;">
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-12">
                                      <div class="form-group">
                                          <label for="apellido_materno">Apellido materno *</label>
                                          <input [(ngModel)]="candidato.apellido_materno" name="apellido_materno" type="text" class="form-control" id="apellido_materno" style="text-transform: uppercase;">
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                          <label for="nombre">Nombres *</label>
                                          <input [(ngModel)]="candidato.nombre" name="nombre" type="text" class="form-control" id="nombre" style="text-transform: uppercase;">
                                      </div>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-4 col-sm-12">
                                    <div class="form-group">
                                      <label for="curp">CURP</label>
                                      <input [(ngModel)]="candidato.curp" name="rfc" type="text" class="form-control" id="curp" style="text-transform: uppercase;" maxlength="18">
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-sm-12">
                                    <div class="form-group">
                                      <label for="rfc">RFC</label>
                                      <input [(ngModel)]="candidato.rfc" name="rfc" type="text" class="form-control" id="rfc" style="text-transform: uppercase;" maxlength="14">
                                    </div>
                                  </div>
                                  <div class="col-lg-4" col-sm-12>
                                    <div class="form-group">
                                      <label for="numero_social">Número de seguro social</label>
                                      <input [(ngModel)]="candidato.numero_social" name="numero_social" type="text" class="form-control" id="numero_social">
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                              <label for="fecha_nacimiento">Fecha de nacimiento</label>
                              <input [(ngModel)]="candidato.fecha_nacimiento" name="fecha_nacimiento" type="date" class="form-control" id="fecha_nacimiento" (change)="generarEdad()">
                            </div>
                          </div>
                          <div class="col-lg-2 col-sm-12">
                            <div class="form-group">
                              <label for="edad">Edad</label>
                              <input [(ngModel)]="candidato.edad" name="edad" type="number" class="form-control" id="edad" maxlength="3">
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                                <label for="correo">Correo</label>
                                <input [(ngModel)]="candidato.correo" name="correo" type="text" class="form-control" id="correo">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-lg-4 col-sm-12">
                            <label for="tel_1">Telefono(s)</label>
                            <input [(ngModel)]="candidato.telefono" name="telefono" type="text" class="form-control" id="tel_1" placeholder="Ingrese el número" maxlength="10">
                          </div>
                          <div class="form-group col-lg-4 col-sm-12">
                            <label for="tel_2">&nbsp;</label>
                            <input [(ngModel)]="candidato.telefono_dos" name="telefono_dos" type="text" class="form-control" id="tel_2" placeholder="Ingrese el número" maxlength="10">
                          </div>
                          <div class="form-group col-lg-4 col-sm-12">
                            <label for="tel_3">&nbsp;</label>
                            <input [(ngModel)]="candidato.telefono_tres" name="telefono_tres" type="text" class="form-control" id="tel_3" placeholder="Ingrese el número" maxlength="10">
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="desc_cempresa">Descripción de habilidades</label>
                                    <textarea [(ngModel)]="candidato.descripcion" name="desc_cempresa" class="form-control" rows="5" id="desc_cempresa" style="text-transform: uppercase;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div class="tab-pane col-12" id="direccion_tab" role="tabpanel" style="height: 450px;overflow-y: auto;">
                <div class="container mt-3">
                  <div class="row">
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Calle</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.calle" name="calle" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Num. interior</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.numero_interior" name="ni" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Num. exterior</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.numero_exterior" name="ne" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Cruzamientos</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.cruzamiento_uno" name="cruza" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-5 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Colonia</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.colonia" name="colonia" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>C.P</mat-label>
                        <input matInput #postalCode maxlength="5" [(ngModel)]="candidato.direccion.codigo_postal">
                        <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Buscar estado</mat-label>
                        <input type="text"
                                placeholder="Ingrese del estado"
                                aria-label="Number"
                                matInput
                                (keyup)="buscarEstado()"
                                [formControl]="filterControlEstado"
                                [matAutocomplete]="autodos"
                                style="text-transform: uppercase;">
                        <mat-autocomplete #autodos="matAutocomplete" (optionSelected)="optionEstado($event)">
                            <mat-option *ngFor="let estado of estados_busqueda" [value]="estado.estado" [id]="estado.id_estado">
                                {{estado.estado}}
                            </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Localidad</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.localidad" name="loca" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Municipio</mat-label>
                        <input matInput [(ngModel)]="candidato.direccion.municipio" name="muni" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Referencias</mat-label>
                        <textarea matInput placeholder="Redacte una breve referencia de la empresa" [(ngModel)]="candidato.direccion.descripcion" name="ref" style="text-transform: uppercase;"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="bandera_activo" class="tab-pane col-12" id="movientos_tab" role="tabpanel" style="height: 450px;overflow-y: auto;">
                <div class="row">
                  <div class="col-lg-6 col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Empresa</mat-label>
                      <input matInput [(ngModel)]="info_contrato.informacion_contrato.empresa" type="text" min="0">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Departamento</mat-label>
                      <input matInput [(ngModel)]="info_contrato.informacion_contrato.departamento" type="text" min="0">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Puesto</mat-label>
                      <input matInput [(ngModel)]="info_contrato.informacion_contrato.puesto" type="text" min="0">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Fecha de alta</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="info_contrato.informacion_contrato.fecha_alta">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Fecha de antiguedad</mat-label>
                        <input matInput [matDatepicker]="pickerDos" [(ngModel)]="info_contrato.informacion_contrato.fecha_antiguedad">
                        <mat-datepicker-toggle matSuffix [for]="pickerDos"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDos></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Sueldo diario</mat-label>
                            <input matInput [(ngModel)]="info_contrato.informacion_contrato.sueldo_diario" type="text" min="0">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Sueldo neto</mat-label>
                            <input matInput [(ngModel)]="info_contrato.informacion_contrato.sueldo_integrado" type="text" min="0">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                  <h3>Movimientos</h3>
                  <div class="table-responsive col-md-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="1">Tipo de movimiento</th>
                          <th colspan="1">Fecha de movimiento</th>
                          <th colspan="1">Observación</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let movimiento of info_contrato.movimientos">

                          <td>
                            <i *ngIf="movimiento.tipo_movimiento == 'A'" class="fas fa-long-arrow-alt-up text-success mr-3"></i>
                            <i *ngIf="movimiento.tipo_movimiento == 'M'" class="fas fa-retweet text-warning mr-3"></i>
                            <i *ngIf="movimiento.tipo_movimiento == 'B'" class="fas fa-long-arrow-alt-down text-danger mr-3"></i>
                            {{movimiento.tipo_movimiento}}
                          </td>
                          <td>{{movimiento.fecha_movimiento}}</td>
                          <td>{{movimiento.observacion}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_modal == 1" type="button" class="btn btn-success" (click)="altaCandidato()">Guardar</button>
        <button *ngIf="tipo_modal == 2" type="button" class="btn btn-success" (click)="modificarCandidato()" >Guardar</button>
    </div>
</ng-template>

<ng-template #modal_camera let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h2 class="modal-title" id="tituloModal">Tomar fotografia</h2>
  </div>
  <div class="modal-body">
      <div class="container">
          <div class="row">
              <div *ngIf="isCameraExist; else noCameraExist" class="col-12">
                  <div class="border border-success rounded text-center" style="width: 100%;height: 265px;">
                      <webcam [height]="450" [width]="350" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                          *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
                  </div>

                  <div *ngIf="errors.length > 0">
                      <h4>Error Messages:</h4>
                      <ul *ngFor="let error of errors">
                          <li>{{ error | json }}</li>
                      </ul>
                  </div>
              </div>

              <ng-template #noCameraExist>
                  <div class="border border-danger rounded text-center"
                  style="display: flex;justify-content: center;align-items: center;width: 450px;height: 350px;">
                      Este dispositivo no cuenta con una camara disponible
                  </div>
              </ng-template>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-warning" (click)="cerrarModalCamera()">Cerrar</button>
      <button *ngIf="isCameraExist" type="button" class="btn btn-success" (click)="takeSnapshot()">Tomar foto</button>
  </div>
</ng-template>
