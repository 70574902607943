<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow" style="height: 550px;">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                <h3 class="mb-0">CATÁLOGO DE DEPARTAMENTO</h3>
            </div>
        </div>
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-lg-4 col-sm-12 mb-2">
                    <div class="buscador">
                        <mat-form-field class="w-100">
                            <mat-label>Buscar departamento</mat-label>
                            <input #input
                                   type="text"
                                   placeholder="Buscar..."
                                   (keyup)="busqueda(input.value)"
                                   aria-label="Buscar departamento"
                                   matInput
                                   [formControl]="myControl"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getDepartamento($event)'>
                              <mat-option *ngFor="let option of departamentos_busqueda" [value]="option.departamento" [id]="option.id_departamento+''">
                                {{option.departamento}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="status" (selectionChange)="mostrarDepartamentos()">
                            <mat-option [value]="2">Todos</mat-option>
                            <mat-option [value]="1">Activos</mat-option>
                            <mat-option [value]="0">Inactivos</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12" style="text-align: end;">
                    <a class="btn btn-info" (click)="guardar()" title="Nuevo departamento"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">No. departamento</th>
                            <th scope="col">Departamento</th>
                            <th scope="col" class="text-center">Vacantes</th>
                            <th scope="col" class="text-center">Autorizados</th>
                            <th scope="col" class="text-center">Status</th>
                            <th scope="col" class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="band; else pintaVacio" class="">
                        <tr *ngFor="let departamento of departamentos">
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.folio}}</td>
                            <td colspan="1" style="vertical-align: middle;">{{departamento.departamento}}</td>
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.vacantes}}</td>
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.autorizados}}</td>
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.status}}</td>
                            <td colspan="1" style="width: 20%;" class="text-center">
                                <a title="Editar" (click)="editar(departamento.folio)" style="cursor: pointer;" class="btn btn-yellow btn-sm"><img src="./assets/iconos/edit.svg" style="width: 14px;"></a>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #pintaVacio>
                        <tbody>
                            <tr>
                                <td colspan="6" class="text-center">No existen departamentos</td>
                            </tr>
                        </tbody>
                    </ng-template>
                    <tbody></tbody>
                </table>
                <div class="filtros container mt-3">
                    <div class="row">
                        <div class="col-lg-5 col-sm-12"></div>
                        <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                            <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                <ul class="pagination">
                                  <li class="page-item" *ngIf="previous">
                                    <a class="page-link" href="#" aria-label="Previous">
                                      <span aria-hidden="true">&laquo;</span>
                                      <span class="sr-only">Previous</span>
                                    </a>
                                  </li>
                                  <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                  <li class="page-item" *ngIf="next">
                                    <a class="page-link" href="#" aria-label="Next">
                                      <span aria-hidden="true">&raquo;</span>
                                      <span class="sr-only">Next</span>
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                        </div>
                        <div class="col-lg-2 col-sm-12" style="text-align: end;">
                            <mat-form-field class="w-100">
                                <mat-label>No. resultados</mat-label>
                                <mat-select [(ngModel)]="taken" (selectionChange)="mostrarDepartamentos()">
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="20">20</mat-option>
                                    <mat-option [value]="30">30</mat-option>
                                    <mat-option [value]="40">40</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 class="modal-title" id="tituloModal">Nuevo departamento</h2>
    </div>
    <div class="modal-body">
        <div class="form-row col-12 mb-2" style="display: flex;justify-content: flex-end;">
            <div class="custom-control custom-switch">
                <input [(ngModel)]="activo" name="activo" type="checkbox" class="custom-control-input" id="customSwitch1">
                <label *ngIf="activo" class="custom-control-label" for="customSwitch1">Activado</label>
                <label *ngIf="!activo" class="custom-control-label" for="customSwitch1">Desactivado</label>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-9">
                <label for="departamento">Departamento *</label>
                <input [(ngModel)]="departamento.departamento" type="text" class="form-control text-uppercase" id="departamento">
            </div>
            <div class="form-group col-sm-3">
                <label for="disponibilidad">Disponibilidad *</label>
                <input [(ngModel)]="departamento.disponibilidad" type="number" class="form-control" id="disponibilidad" min="1" pattern="^[1-9]+" disabled>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-12">
                <label for="descripcion">Descripcion</label>
                <textarea [(ngModel)]="departamento.descripcion" type="text" class="form-control" id="descripcion" style="text-transform: uppercase;"></textarea>
            </div>
        </div>
        <div class="form-row">
            <h2>Nuevo puesto</h2>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-9">
                <label for="puesto">Puesto</label>
                <input [(ngModel)]="puesto.puesto" type="text" class="form-control text-uppercase" id="puesto">
            </div>
            <div class="form-group col-sm-3">
                <label for="disponibilidad">Autorizados</label>
                <input [(ngModel)]="puesto.autorizados" type="number" class="form-control" id="disponibilidad" min="1" pattern="^[1-9]+">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-4">
                <label for="sueldo_tipo_a">Sueldo tipo A</label>
                <input [(ngModel)]="puesto.sueldo_tipo_a" type="number" class="form-control" id="sueldo_tipo_a">
            </div>
            <div class="form-group col-sm-4">
                <label for="sueldo_tipo_b">Sueldo tipo B</label>
                <input [(ngModel)]="puesto.sueldo_tipo_b" type="number" class="form-control" id="sueldo_tipo_b">
            </div>
            <div class="form-group col-sm-4">
                <label for="sueldo_tipo_c">Sueldo tipo C</label>
                <input [(ngModel)]="puesto.sueldo_tipo_c" type="number" class="form-control" id="sueldo_tipo_c">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-12">
                <label for="descripcion">Descripcion</label>
                <textarea [(ngModel)]="puesto.descripcion" type="text" class="form-control" id="descripcion" style="text-transform: uppercase;"></textarea>
            </div>
        </div>
        <div class="form-row float-right">
            <button class="btn btn-success" (click)=agregarPuesto() id="agregarPuesto">Agregar</button>
            <button class="btn btn-warning" (click)=limpiaTexto() id="limpiaTexto" style="display: none;">Limpiar</button>
            <button class="btn btn-success" (click)=agregarPuesto() id="guardaPuesto" style="display: none;">Guardar</button>
        </div>
        <div class="form-row mt-5">
            <div class="table-responsive col-sm-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Puestos</th>
                            <th scope="col">Descripcion</th>
                            <th scope="col" style="width: 5%;text-align: center;">Autorizados</th>
                            <th scope="col" style="width: 5%;text-align: center;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="band_puestos">
                        <tr *ngFor="let puesto_array of puestos" (click)="editarPuesto(puesto_array.id_puesto)" style="cursor: pointer;">
                            <td style="vertical-align: middle;">{{puesto_array.puesto}}</td>
                            <td style="vertical-align: middle;">{{puesto_array.descripcion}}</td>
                            <td style="vertical-align: middle;" style="width: 5%;text-align: center;">
                                <input type="number" name="" id="valor_{{puesto_array.id_puesto}}" value="{{puesto_array.autorizados}}" class="form-control" (change)="cambiarValor(puesto_array.id_puesto,puesto_array.autorizados)">
                            </td>
                            <td style="vertical-align: middle;" ><a class="btn btn-info" (click)="eliminarPuesto(puesto_array.id_puesto)" title="Nuevo departamento"><img src="./assets/iconos/delete.svg" style="width: 24px;"></a></td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!band_puestos">
                        <tr></tr>
                        <tr class="text-center">
                            <td colspan="4">Este departamento no cuenta con puestos</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button type="button" class="btn btn-success" (click)="altaDepartamento()" id="guardar">Guardar</button>
        <!-- <button type="button" class="btn btn-success" (click)="modificarUsuario()" id="editar" >Editar</button> -->
    </div>
</ng-template>
  