<!-- Modal -->
<div class="modal fade" id="modalCancelacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nuevo movimiento</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form #c="ngForm" ngNativeValidate >
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputCity">Concepto</label>
                    <select name="tipo" class="form-control">
                        <option selected value="">Seleccione un concepto</option> 
                                                    
                    </select>                </div>
                <div class="form-group col-md-6">
                    <label for="inputCity">Estatus</label>
                    <select name="tipo" class="form-control">
                        <option selected value="">Seleccione un estatus</option>                                                     
                    </select> 
                </div>                
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputCity">Fecha del movimiento</label>
                    <input type="date" class="form-control" >
                </div>
                <div class="form-group col-md-6">
                    <label for="inputCity">Fecha del pago</label>
                    <input type="date" class="form-control">
                </div>                
            </div>
            <div class="form-group">
                <label for="xml">Beneficiario</label>
                <input type="text" class="form-control" >
            </div>
            <div class="form-group">
                <label for="xml">Importe</label>
                <input type="number" class="form-control" >
            </div>
            <div class="form-group">
                <label for="xml">Descripción</label>
                <textarea type="text" class="form-control" rows="4" required></textarea>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary">Guardar</button>
        </div>
      </form>
      </div>
    </div>
  </div>


