<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">AUTORIZAR JUSTIFICACIONES</h4>
          <!-- BUSCADOR -->
          <div class="card-category">
            <div class="row">
              <div class="col-md-6 col-sm-12 d-flex align-items-center">
                <mat-form-field class="w-100 px-1">
                  <mat-label class="d-flex justify-content-between align-items-center">Buscar trabajador <i
                      class="fa fa-search" aria-hidden="true"></i></mat-label>
                  <input type="text" placeholder="NOMBRE o RFC" aria-label="Number" matInput (keyup)="buscarCandidato()"
                    [formControl]="filterControl" [matAutocomplete]="auto" style="text-transform: uppercase;">
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionUsuario($event)">
                    <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                      [id]="empleado">
                      {{empleado.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <button class="btn btn-sm btn-success"><i class="fa fa-search" aria-hidden="true"></i></button> -->
              </div>
              <div class="col">
                <mat-checkbox class="mt-3" [(ngModel)]="checked" (change)="checkOn($event)"><b>Autorizadas</b></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <!-- CARDS -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 mb-3" *ngFor="let justificacion of justificaciones">
              <mat-card class="custom-card" (click)="autorizar(justificacion.id_justificacion)">
                <mat-card-content>
                  <div class="row">
                    <div class="col">
                   <img [src]="justificacion.fotografia"  alt="Imagen" class="card-image border ">
                 </div>
                 <div class="col text_info">
                      <h4 class="nomobre">{{justificacion.nombre_completo}}</h4>
                      <p *ngIf="justificacion.autorizado == 0" class=" mb-0 badge badge-warning">NO autorizado</p>
                      <p *ngIf="justificacion.autorizado == 1" class=" mb-0 badge badge-success">Autorizado</p>
                      <p class="card-label"><strong>Folio:</strong>{{justificacion.folio}}</p>
                      <p class="card-label"><strong>Puesto:</strong> {{justificacion.puesto}}</p>
                      <p class= " fecha card-label"><strong>{{justificacion.fecha | date: 'dd/MM/yyyy' }}</strong></p>
                      <!-- <p>Autorizado: <i [ngClass]="{'badge badge-warning': justificacion.autorizado == 0, 'ni ni-check-bold': justificacion.autorizado == 1}"></i></p> -->
                    </div>
                 </div>
                  <div class="row ml-3 ">
                    <p class="card-label"><strong>Motivo:</strong> <br>
                      <span class="motivo">{{justificacion.motivo}}</span>
                    </p>

                  </div>


                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- MODAL -->
<!-- <ng-template #content let-c="close" let-d="dismiss">
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h1>Agregar Incapacidad</h1>
      </div>
      <form class="container">
          <div class="card-category">
            <div class="row">
              <div class="col-md-6 col-sm-12 d-flex align-items-center">
                <mat-form-field class="w-100 px-1" *ngIf="id_perfil == 3">
                  <mat-label class="d-flex justify-content-between align-items-center">Buscar trabajador <i
                      class="fa fa-search" aria-hidden="true"></i></mat-label>
                  <input type="text" placeholder="NOMBRE o RFC" aria-label="Number" matInput (keyup)="buscarCandidato()"
                    [formControl]="filterControl" [matAutocomplete]="auto" style="text-transform: uppercase;">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                      [id]="empleado.id_empleado">
                      {{empleado.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="example-full-width">
                  <input [(ngModel)]="incapacidad.folio"  style="text-transform: uppercase;" name="folio" matInput placeholder="Folio">
                </mat-form-field>
              </div>
            </div>
          </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <input  [(ngModel)]="incapacidad.fecha_inicial" name="fecha_inicial"  matInput [matDatepicker]="picker" placeholder="Fecha Inicio">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <input   [(ngModel)]="incapacidad.fecha_final" name="fecha_final" matInput [matDatepicker]="picker2" placeholder="Fecha Final">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-md-12 d-flex justify-content-end">
            <button (click)="closeModal()" class="btn btn-secondary">Cancelar</button>
            <button  (click)="guardarIncapacidad()"  type="submit"t class="btn btn-primary">Guardar</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</ng-template> -->




