<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h3 class="title">BIENVENIDO</h3>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 mt-3 pt-3 col-md-4">
            <img src="{{foto_empresa}}" alt="Empresa Logo" class="ml-2 card-img">
          </div>
          <div class="col-12 col-md-4">
            <div class="card-body">
              <h3 class="fecha"><span>{{ fecha }}</span></h3>
              <div class="col text-center">
                <button (click)="registrar()" class=" btn-block btn btn-primary">Registrar</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
