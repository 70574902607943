<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow" style="height: 550px;">
          <div class="card-header border-0">
              <div class="row align-items-center">
                  <div class="col">
                  <h3 class="mb-0">CIERRE DE NÓMINA</h3>
              </div>
          </div>
          <div class="card-body">
              
          </div>
        </div>
      </div>
    </div>
  </div>

