import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema_timbrado',
  templateUrl: './sistema_timbrado.component.html',
  styleUrls: ['./sistema_timbrado.component.css']
})
export class SistemaTimbradoComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
