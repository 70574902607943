<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
<div class="row">
    <div class="col">
    <div class="card border-info">
        <div class="card-header">
            EMPRESAS
        </div>
        <div class="card-body">
            
        </div>
    </div>
    </div>
</div>
</div>
    