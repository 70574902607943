
<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col">
      <div class="card border-info">
          <div class="card-header">
            CATÁLOGO DE NÓMINAS
          </div>
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-lg-4 col-sm-12 mb-2">
                    <mat-form-field class="w-100">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="status" (selectionChange)="mostrarNominas()">
                            <mat-option [value]="-1">Todos</mat-option>
                            <mat-option [value]="1">Activos</mat-option>
                            <mat-option [value]="0">Inactivos</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-sm-12"></div>
                <div class="col-lg-4 col-sm-12" style="text-align: end;">
                    <a class="btn btn-info" title="Nuevo tipo de nómina" (click)="openModal()"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr class="text-center">
                            <th scope="col" class="text-center">No. nómina</th>
                            <th scope="col">Tipo de nómina</th>
                            <th scope="col" class="text-center">Estatus</th>
                            <th scope="col" class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="nominas.length > 0; else pintaVacio" class="">
                        <tr *ngFor="let nomina of nominas" class="text-center">
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{nomina.id_empresa_nomina}}</td>
                            <td colspan="1" style="vertical-align: middle;">{{nomina.nomina}}</td>
                            <td colspan="1" style="vertical-align: middle;" class="text-center">{{nomina.id_status}}</td>
                            <td colspan="1" style="width: 20%;" class="text-center">
                                <a *ngIf="nomina.activo == 1" title="Eliminar" (click)="eliminarLiga(nomina.id_empresa_nomina)" style="cursor: pointer;" class="btn btn-danger btn-sm"><img src="./assets/iconos/delete.svg" style="width: 14px;"></a>
                                <a *ngIf="nomina.activo == 0" title="Activar" (click)="activarLiga(nomina.id_empresa_nomina)" style="cursor: pointer;" class="btn btn-success btn-sm"><i class="far fa-check-square text-white"></i></a>
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #pintaVacio>
                        <tbody>
                            <tr>
                                <td colspan="6" class="text-center">No existen departamentos</td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
                <div class="filtros container mt-3">
                    <div class="row">
                        <div class="col-lg-5 col-sm-12"></div>
                        <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                            <!-- <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                <ul class="pagination">
                                  <li class="page-item" *ngIf="previous">
                                    <a class="page-link" href="#" aria-label="Previous">
                                      <span aria-hidden="true">&laquo;</span>
                                      <span class="sr-only">Previous</span>
                                    </a>
                                  </li>
                                  <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                  <li class="page-item" *ngIf="next">
                                    <a class="page-link" href="#" aria-label="Next">
                                      <span aria-hidden="true">&raquo;</span>
                                      <span class="sr-only">Next</span>
                                    </a>
                                  </li>
                                </ul>
                              </nav> -->
                        </div>
                        <div class="col-lg-2 col-sm-12" style="text-align: end;">
                            <mat-form-field class="w-100">
                                <mat-label>No. resultados</mat-label>
                                <mat-select [(ngModel)]="taken" (selectionChange)="mostrarNominas()">
                                    <mat-option [value]="5">5</mat-option>
                                    <mat-option [value]="10">10</mat-option>
                                    <mat-option [value]="20">20</mat-option>
                                    <mat-option [value]="30">30</mat-option>
                                    <mat-option [value]="40">40</mat-option>
                                    <mat-option [value]="50">50</mat-option>
                                    <mat-option [value]="100">100</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h2 class="modal-title" id="tituloModal">Agregar nómina</h2>
    </div>
    <div class="modal-body">
        <div class="form">
            <div class="form-row">
                <mat-form-field class="w-100">
                    <mat-label>Tipo de nómina</mat-label>
                    <mat-select [(ngModel)]="tipo_nomina">
                        <mat-option *ngFor="let nomina of catalogo_nomina" [value]="nomina.id_nomina">{{nomina.nomina}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button type="button" class="btn btn-success" (click)="altaNomina()" id="guardar">Guardar</button>
    </div>
</ng-template>
  