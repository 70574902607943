import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-procedimiento-calculo',
    templateUrl: './procedimiento_calculo.component.html',
    styleUrls: ['./procedimiento_calculo.component.css']
})
export class ProcedimientoCalculoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
      }
}