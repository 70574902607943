<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
      <div class="col-lg-12 col-sm-12">
          <div class="card">
              <div class="card-header">
                <h4 class="card-title">CLIENTES</h4>
                <div class="card-category">
                  <div class="row align-items-center">
                      <div class="col-lg-4 col-sm-12">
                          <mat-form-field class="w-100">
                              <mat-label>Buscar cliente</mat-label>
                              <input type="text"
                                      placeholder="Ingrese el nombre o id del cliente"
                                      aria-label="Number"
                                      matInput
                                      (keyup)="buscarCliente()"
                                      [formControl]="filterControlCliente"
                                      [matAutocomplete]="auto"
                                      style="text-transform: uppercase;">
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionCliente($event)">
                                  <mat-option *ngFor="let cliente of clientes_busqueda" [value]="cliente.cliente" [id]="cliente.id_cliente">
                                      {{cliente.cliente}}
                                  </mat-option>
                              </mat-autocomplete>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="status" (selectionChange)="mostrarClientes()">
                              <mat-option [value]="2">Todos</mat-option>
                              <mat-option [value]="1">Activos</mat-option>
                              <mat-option [value]="0">Inactivos</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-sm-12 text-right">
                          <button class="btn btn-info" (click)="nuevoCliente()"><i class="fas fa-plus"></i></button>
                      </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                  <div class="row">
                      <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                          <table mat-table [dataSource]="dataSource" class="w-100">
                              
                              <ng-container matColumnDef="Id">
                                  <th mat-header-cell *matHeaderCellDef> Id </th>
                                  <td mat-cell *matCellDef="let element"> {{element.id_cliente}} </td>
                              </ng-container>

                              <ng-container matColumnDef="Cliente">
                                  <th mat-header-cell *matHeaderCellDef> Cliente </th>
                                  <td mat-cell *matCellDef="let element"> {{element.cliente}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="Estatus">
                                  <th mat-header-cell *matHeaderCellDef> Estatus </th>
                                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                              </ng-container>
  
                              <ng-container matColumnDef="Accion">
                                  <th mat-header-cell *matHeaderCellDef> Acción </th>
                                  <td mat-cell *matCellDef="let element">  
                                      <button [matMenuTriggerFor]="menu" class="btn btn-info btn-sm"><i class="fas fa-ellipsis-v"></i></button>
                                      <mat-menu #menu="matMenu">
                                          <button mat-menu-item (click)="editar(element.id_cliente)">
                                              <mat-icon><i class="far fa-eye"></i></mat-icon>
                                              <span>Ver</span>
                                          </button>
                                      </mat-menu>
                                  </td>
                              </ng-container>
      
                              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                          <mat-paginator 
                              [pageSizeOptions]="[5, 10, 20]"
                              showFirstLastButtons 
                              aria-label="Select page of periodic elements">
                          </mat-paginator>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 class="modal-title" *ngIf="tipo_modal == 1" id="tituloModal">Nuevo cliente</h2>
        <h2 class="modal-title" *ngIf="tipo_modal == 2" id="tituloModal">Editar cliente</h2>
    </div>
    <div class="modal-body">
      <mat-tab-group mat-align-tabs="center" class="mt-2" style="height: 300px;">
        <mat-tab label="Información general">
          <div class="row w-100 mt-3">
            <div class="col-sm-12 col-lg-4" style="position: relative;">
              <img [src]="foto_user" style="height: 120px;width: 100%;" class="img-fluid">
              <div [matMenuTriggerFor]="menu" class="img-circle d-flex align-items-center justify-content-center" style="height: 30px;width:30px;position: absolute;right:0;bottom:0;border: 1px solid gray;background-color: gray;border-radius: 50%;cursor: pointer;">
              <i class="fas fa-pencil-alt" style="color: white;"></i>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item disabled>
                  <mat-icon><i class="fas fa-camera"></i></mat-icon>
                    <span>Tomar foto</span>
                  </button>
                  <button mat-menu-item (click)="subirImagen()">
                    <mat-icon><i class="fas fa-file-upload"></i></mat-icon>
                      <span>Subir imagen</span>
                    </button>
                </mat-menu>
                <input type="file" id="foto_user" (change)="cambiarImagen($event)" style="display: none;">
              </div>
            </div>
            <div class="col-sm-12 col-lg-8">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <mat-form-field class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput [(ngModel)]="cliente.cliente" name="empresa" style="text-transform: uppercase;" required>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <mat-form-field class="w-100">
                    <mat-label>Contacto</mat-label>
                    <input matInput [(ngModel)]="cliente.contacto" name="razon" required>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-lg-12 col-sm-12">
              <mat-form-field class="w-100">
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Redacte una breve descripción acerca de la empresa" [(ngModel)]="cliente.descripcion" name="desc" style="text-transform: uppercase;"></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Dirección">
          <div class="container mt-3">
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Calle</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.calle" name="calle" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Num. interior</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.numero_interior" name="ni" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Num. exterior</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.numero_exterior" name="ne" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Cruzamientos</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.cruzamiento_uno" name="cruza" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Colonia</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.colonia" name="colonia" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>C.P</mat-label>
                  <input matInput #postalCode maxlength="5" [(ngModel)]="cliente.direccion.codigo_postal">
                  <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Buscar estado</mat-label>
                  <input type="text"
                          placeholder="Ingrese del estado"
                          aria-label="Number"
                          matInput
                          (keyup)="buscarEstado()"
                          [formControl]="filterControlEstado"
                          [matAutocomplete]="autodos"
                          style="text-transform: uppercase;">
                  <mat-autocomplete #autodos="matAutocomplete" (optionSelected)="optionEstado($event)">
                      <mat-option *ngFor="let estado of estados_busqueda" [value]="estado.estado" [id]="estado.id_estado">
                          {{estado.estado}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Localidad</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.localidad" name="loca" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Municipio</mat-label>
                  <input matInput [(ngModel)]="cliente.direccion.municipio" name="muni" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Referencias</mat-label>
                  <textarea matInput placeholder="Redacte una breve referencia de la empresa" [(ngModel)]="cliente.direccion.descripcion" name="ref" style="text-transform: uppercase;"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button type="button" *ngIf="tipo_modal == 1" class="btn btn-success" (click)="altaCliente()">Guardar</button>
        <button type="button" *ngIf="tipo_modal == 2" class="btn btn-success" (click)="modificarUsuario()">Guardar</button>
    </div>
</ng-template>
  