<div class="{{color}}" style="height: 250px;"></div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Empleados &nbsp;&nbsp;&nbsp;&nbsp; activos</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[0]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                <i class="fas fa-users"></i>
              </div>
            </div>
            <!-- <p class="mt-3 mb-0 text-muted text-sm">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Empleados Autorizados</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[1]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                <i class="fas fa-users"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 3.48%</span>
            <span class="text-nowrap">Since last week</span>
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Vacantes disponibles</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[2]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                <i class="fas fa-chart-pie"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
            <span class="text-nowrap">Since yesterday</span>
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Archivos por procesar</h5>
              <span class="h2 font-weight-bold mb-0">{{targeta[3]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                <i class="fas fa-percent"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 12%</span>
            <span class="text-nowrap">Since last month</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-6">
      <div class="card shadow" style="height: 550px;">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Mis puestos</h3>
            </div>
            <!-- <div class="col text-right">
              <a href="#!" class="btn btn-sm btn-primary">Ver todos</a>
            </div> -->
          </div>
        </div>
        <div class="table-responsive">
          <!-- Projects table -->
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="text-left">Puesto</th>
                <th scope="col">Autorizados</th>
                <th scope="col">Contratados</th>
                <th scope="col">Vacantes</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let puesto of puestos" class="text-center">
                <th scope="row" class="text-left">{{puesto.puesto}}</th>
                <td>{{puesto.autorizados}}</td>
                <td>{{puesto.contratados}}</td>
                <td>{{puesto.vacantes}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card shadow" style="height: 550px;">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Ultimos movimientos</h3>
            </div>
            <!-- <div class="col text-right">
              <a href="#!" class="btn btn-sm btn-primary">Ver todos</a>
            </div> -->
          </div>
        </div>
        <div class="table-responsive">
          <!-- Projects table -->
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th scope="col" class="text-center">Tipo de movimiento</th>
                <th scope="col">Usuario</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody *ngIf="band_mov; else sino">
              <tr *ngFor="let movimiento of movimientos">
                <th scope="row" class="text-center">
                  <i *ngIf="movimiento.id_movimiento == 1" class="fas fa-long-arrow-alt-up text-success mr-3"></i>
                  <i *ngIf="movimiento.id_movimiento == 3" class="fas fa-retweet text-warning mr-3"></i>
                  <i *ngIf="movimiento.id_movimiento == 2" class="fas fa-long-arrow-alt-down text-danger mr-3"></i>
                  {{movimiento.tipo_movimiento}}
                </th>
                <td>{{movimiento.nombre}}</td>
                <td>{{movimiento.fecha_movimiento}}</td>
              </tr>
            </tbody>
            <ng-template #sino>
              <tbody #sino>
                <tr class="text-center">
                  <th scope="row" colspan="3">Aún sin movimientos</th>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>