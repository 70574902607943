export class Usuario {
    constructor(
        public id_usuario : number,
        public usuario : String,
        public password : String,
        public nombre : String,
        public status : String,
        public usuario_creacion : number
    ){}
    
}