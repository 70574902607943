<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-6">
      <div class="card bg-gradient-default shadow">
          <div class="card-header bg-transparent">
              <div class="row align-items-center">
              <div class="col">
                  <h6 class="text-uppercase text-light ls-1 mb-1">Vista Previa</h6>
                  <h2 class="text-white mb-0">Importes de nómina</h2>
              </div>
              <div class="col">
                  <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0" data-toggle="chart" data-target="#chart-sales">
                      <a href="javascript:void(0)" class="nav-link py-2 px-3"  data-toggle="tab">
                      <span class="d-none d-md-block">Mes</span>
                      <span class="d-md-none">M</span>
                      </a>
                  </li>
                  <li class="nav-item" data-toggle="chart" data-target="#chart-sales"  >
                      <a href="javascript:void(0)" class="nav-link py-2 px-3"  data-toggle="tab">
                      <span class="d-none d-md-block">Semana</span>
                      <span class="d-md-none">W</span>
                      </a>
                  </li>
                  </ul>
              </div>
              </div>
          </div>
          <div class="card-body">
              <!-- Chart -->
              <div class="chart">
                <canvas id="chart-orders" class="chart-canvas"></canvas>
              </div>
          </div>
      </div>
  </div>
    <div class="col-6">
      <div class="card shadow" style="height: 550px;">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Ultimos movimientos</h3>
            </div>
            <!-- <div class="col text-right">
              <a href="#!" class="btn btn-sm btn-primary">Ver todos</a>
            </div> -->
          </div>
        </div>
        <div class="table-responsive">
          <!-- Projects table -->
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th scope="col" class="text-center">Tipo de movimiento</th>
                <th scope="col">Usuario</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody *ngIf="band_mov; else sino">
              <tr *ngFor="let movimiento of movimientos">
                <th scope="row" class="text-center">
                  <i *ngIf="movimiento.id_movimiento == 1" class="fas fa-arrow-up text-success mr-3"></i>
                  <i *ngIf="movimiento.id_movimiento == 2" class="fas fa-arrow-down text-danger mr-3"></i>
                  <i *ngIf="movimiento.id_movimiento == 3" class="fas fa-arrow-up text-success mr-3"></i>
                  <i *ngIf="movimiento.id_movimiento == 3" class="fas fa-arrow-down text-danger mr-3"></i>
                  {{movimiento.tipo_movimiento}}
                </th>
                <td>{{movimiento.nombre}}</td>
                <td>{{movimiento.fecha_movimiento}}</td>
              </tr>
            </tbody>
            <ng-template #sino>
              <tbody #sino>
                <tr class="text-center">
                  <th scope="row" colspan="3">Aún sin movimientos</th>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

