<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <div class="card">
                <div class="card-header">
                  <h4 class="card-title">SUCURSALES</h4>
                  <div class="card-category">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>Seleccionar empresa</mat-label>
                                <input type="text"
                                        placeholder="Ingrese el nombre o id de la empresa"
                                        aria-label="Number"
                                        matInput
                                        (keyup)="buscarEmpresa()"
                                        [formControl]="filterControlEmpresa"
                                        [matAutocomplete]="auto"
                                        style="text-transform: uppercase;">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                                    <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa" [id]="empresa.id_empresa">
                                        {{empresa.empresa}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                        </div>
                        <div class="col-lg-4 col-sm-12 text-right">
                            <button class="btn btn-info" title="Nueva sucursal" (click)="nuevaSucursal()"><i class="fas fa-plus-square"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                            <table mat-table [dataSource]="dataSource" class="w-100">
                                
                                <ng-container matColumnDef="Id">
                                    <th mat-header-cell *matHeaderCellDef> Id </th>
                                    <td mat-cell *matCellDef="let element"> {{element.id_sucursal}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="Sucursal">
                                    <th mat-header-cell *matHeaderCellDef> Sucursal </th>
                                    <td mat-cell *matCellDef="let element"> {{element.sucursal}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="Representante">
                                    <th mat-header-cell *matHeaderCellDef> Represante Legal </th>
                                    <td mat-cell *matCellDef="let element"> {{element.repre}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="Zona">
                                  <th mat-header-cell *matHeaderCellDef> Zona </th>
                                  <td mat-cell *matCellDef="let element"> {{element.zona}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="accion">
                                    <th mat-header-cell *matHeaderCellDef> Acción </th>
                                    <td mat-cell *matCellDef="let element">  
                                        <button [matMenuTriggerFor]="menu" class="btn btn-info btn-sm"><i class="fas fa-ellipsis-v"></i></button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="visualizar(element.id_sucursal)">
                                                <mat-icon><i class="far fa-eye"></i></mat-icon>
                                                <span>Ver</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>
        
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-paginator 
                                [pageSizeOptions]="[5, 10, 20]"
                                showFirstLastButtons 
                                aria-label="Select page of periodic elements">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 *ngIf="tipo_modal == 1" class="modal-title" id="tituloModal">Nueva sucursal</h2>
        <h2 *ngIf="tipo_modal == 2" class="modal-title" id="tituloModal">{{nombre_sucursal}}</h2>
    </div>
    <div class="modal-body">
        <mat-tab-group mat-align-tabs="center" class="mt-2" style="height: 300px;">
            <mat-tab label="Información general">
                <div class="container mt-3">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <mat-form-field class="w-100">
                          <mat-label>Cliente</mat-label>
                          <mat-select [(ngModel)]="sucursal.id_cliente">
                            <mat-option *ngFor="let cliente of clientes" [value]="cliente.id_cliente">{{cliente.cliente}}</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                              <mat-label>Sucursal</mat-label>
                              <input matInput [(ngModel)]="sucursal.sucursal" name="sucursal" style="text-transform: uppercase;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                              <mat-label>Region</mat-label>
                              <input matInput [(ngModel)]="sucursal.region" name="region" style="text-transform: uppercase;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>Zona</mat-label>
                                <mat-select [(ngModel)]="sucursal.zona">
                                  <mat-option value="A">Zona A</mat-option>
                                  <mat-option value="B">Zona B</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                              <mat-label>Tasa de impuesto estatal</mat-label>
                              <input matInput [(ngModel)]="sucursal.tasa_estatal" name="estatla" type="number" min="0">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                              <mat-label>Tasa de impuesto especial</mat-label>
                              <input matInput [(ngModel)]="sucursal.tasa_especial" name="especial" type="number" min="0">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                              <mat-label>Prima de riesgo</mat-label>
                              <input matInput [(ngModel)]="sucursal.prima_riesgo" name="prima_riesgo" type="number" min="0">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Dirección">
                <div class="container mt-3">
                  <div class="row">
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Calle</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.calle" name="calle" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Num. interior</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.numero_interior" name="ni" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Num. exterior</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.numero_exterior" name="ne" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Cruzamientos</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.cruzamiento_uno" name="cruza" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-5 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Colonia</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.colonia" name="colonia" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>C.P</mat-label>
                        <input matInput #postalCode maxlength="5" [(ngModel)]="sucursal.direccion.codigo_postal">
                        <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Buscar estado</mat-label>
                        <input type="text"
                                placeholder="Ingrese del estado"
                                aria-label="Number"
                                matInput
                                (keyup)="buscarEstado()"
                                [formControl]="filterControlEstado"
                                [matAutocomplete]="autodos"
                                style="text-transform: uppercase;">
                        <mat-autocomplete #autodos="matAutocomplete" (optionSelected)="optionEstado($event)">
                            <mat-option *ngFor="let estado of estados_busqueda" [value]="estado.estado" [id]="estado.id_estado">
                                {{estado.estado}}
                            </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Localidad</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.localidad" name="loca" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Municipio</mat-label>
                        <input matInput [(ngModel)]="sucursal.direccion.municipio" name="muni" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Referencias</mat-label>
                        <textarea matInput placeholder="Redacte una breve referencia de la empresa" [(ngModel)]="sucursal.direccion.descripcion" name="ref" style="text-transform: uppercase;"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Representante legal">
                <div class="container mt-3">
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <mat-form-field class="w-100">
                        <mat-label>Nombre</mat-label>
                        <input matInput [(ngModel)]="sucursal.representante.nombre" name="name_r" style="text-transform: uppercase;">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <mat-form-field class="w-100">
                          <mat-label>RFC</mat-label>
                          <input matInput #rfc maxlength="13" [(ngModel)]="sucursal.representante.rfc" name="repre_r" style="text-transform: uppercase;">
                          <mat-hint align="end">{{rfc.value.length}} / 13</mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <mat-form-field class="w-100">
                          <mat-label>CURP</mat-label>
                          <input matInput #curp maxlength="18" [(ngModel)]="sucursal.representante.curp" name="repre_c" style="text-transform: uppercase;">
                          <mat-hint align="end">{{curp.value.length}} / 18</mat-hint>
                        </mat-form-field>
                      </div>
                  </div>
                </div>
              </mat-tab>
          </mat-tab-group>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_modal == 1" type="button" class="btn btn-success" (click)="altaSucursal()">Guardar</button>
        <button *ngIf="tipo_modal == 2" type="button" class="btn btn-success" (click)="guardar()">Guardar</button>
    </div>
</ng-template>