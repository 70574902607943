<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow" style="height: 550px;">
          <div class="card-header border-0">
              <div class="row align-items-center">
                  <div class="col">
                  <h3 class="mb-0">CATÁLOGO DE PRESTACIONES</h3>
              </div>
          </div>
          <div class="card-body">
              <div class="row mb-2">
                  <div class="col-lg-4 col-sm-12 mb-2">
                      <div class="buscador">
                          <mat-form-field class="w-100">
                              <mat-label>Buscar prestaciones</mat-label>
                              <input #input
                                     type="text"
                                     placeholder="Buscar..."
                                     (keyup)="busqueda(input.value)"
                                     aria-label="Buscar concepto"
                                     matInput
                                     [formControl]="myControl"
                                     [matAutocomplete]="auto">
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getPrestacion($event)'>
                                <!-- <mat-option *ngFor="let option of departamentos_busqueda" [value]="option.departamento" [id]="option.id_departamento+''">
                                  {{option.departamento}}
                                </mat-option> -->
                              </mat-autocomplete>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-12">
                      <mat-form-field class="w-100">
                          <mat-label>Status</mat-label>
                          <mat-select [(ngModel)]="status" (selectionChange)="mostrarPrestaciones()">
                              <mat-option [value]="2">Todos</mat-option>
                              <mat-option [value]="1">Activos</mat-option>
                              <mat-option [value]="0">Inactivos</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="col-lg-4 col-sm-12" style="text-align: end;">
                      <a class="btn btn-info" (click)="guardar()" title="Nueva prestación"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                  </div>
              </div>
              <div class="table-responsive">
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th scope="col" class="text-center">No. presatación</th>
                              <th scope="col">Prestación</th>
                              <th scope="col" class="text-center">Status</th>
                              <th scope="col" class="text-center">Acciones</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="prestaciones.lenght > 0; else pintaVacio" class="">
                          <!-- <tr *ngFor="let departamento of departamentos">
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.folio}}</td>
                              <td colspan="1" style="vertical-align: middle;">{{departamento.departamento}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.vacantes}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.autorizados}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{departamento.status}}</td>
                              <td colspan="1" style="width: 20%;" class="text-center">
                                  <a title="Editar" (click)="editar(departamento.folio)" style="cursor: pointer;" class="btn btn-yellow btn-sm"><img src="./assets/iconos/edit.svg" style="width: 14px;"></a>
                              </td>
                          </tr> -->
                      </tbody>
                      <ng-template #pintaVacio>
                          <tbody>
                              <tr>
                                  <td colspan="4" class="text-center">No existen prestaciones</td>
                              </tr>
                          </tbody>
                      </ng-template>
                  </table>
                  <div class="filtros container mt-3">
                      <div class="row">
                          <div class="col-lg-5 col-sm-12"></div>
                          <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                                <!-- <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                    <ul class="pagination">
                                    <li class="page-item" *ngIf="previous">
                                        <a class="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                    <li class="page-item" *ngIf="next">
                                        <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Next</span>
                                        </a>
                                    </li>
                                    </ul>
                                </nav> -->
                          </div>
                          <div class="col-lg-2 col-sm-12" style="text-align: end;">
                              <mat-form-field class="w-100">
                                  <mat-label>No. resultados</mat-label>
                                  <mat-select [(ngModel)]="taken" (selectionChange)="mostrarPrestaciones()">
                                      <mat-option [value]="5">5</mat-option>
                                      <mat-option [value]="10">10</mat-option>
                                      <mat-option [value]="20">20</mat-option>
                                      <mat-option [value]="30">30</mat-option>
                                      <mat-option [value]="40">40</mat-option>
                                      <mat-option [value]="50">50</mat-option>
                                      <mat-option [value]="100">100</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

