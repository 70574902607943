<div class="header {{color}}" style="height: 250px;">
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <!-- <div class="card-header">
          <h2 class="card-title">PUNTOS DE ACCESO</h2>
        </div> -->
        <mat-tab-group #tabGroup>
          <mat-tab label="Puntos de acceso">
            <div class="col-10 my-3 mx-auto " *ngFor="let acceso of accesos"  >
              <mat-card  class="car">
                <!-- <mat-card-title>
                  {{acceso.ubicacion}}
                </mat-card-title> -->
                <mat-card-content>
                  <div class="row">
                    <div (click)="autorizar(acceso)" class="col-6">
                      <label for="">Ubicacion</label>
                      <h3>{{acceso.ubicacion}}</h3>
                    </div>
                    <div class="col">
                      <label for="">Distancia</label>
                      <h3>{{acceso.distancia_km}}</h3>
                    </div>
                    <div (click)="autorizar(acceso)" class="col">
                      <p style="font-size: 16px;" class="badge badge-success mt-3">Autorizado</p>
                    </div>
                    <div style="cursor: default;" class="col-1 text-center">
                      <a type="button" title="¿Editar?" (click)="editar(acceso)" >
                        <i style="font-size: 18px; color: blue;" class="fa fa-edit mt-3" aria-hidden="true"></i>
                      </a>
                     </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>


          </mat-tab>

          <mat-tab  label="Nuevo punto de acceso">
            <div class="usuarios-container">
              <!-- FORMULARIO -->
              <form class="usuario__form" #usuarioForm="ngForm" (ngSubmit)="guardar()">
                <div class="row">
                    <!-- MAPA -->
                    <div class="col col-md-6 mt-4 d-flex flex-column">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238217.47526880837!2d-89.86481465440576!3d21.106662892806675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f55ddd31322e2c5%3A0xfb7e2d392d94a3e3!2sPETROSERVICIOS%20YUCATAN!5e0!3m2!1ses-419!2smx!4v1698274797583!5m2!1ses-419!2smx"

                      width="600" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <!-- INPUTS -->
                    <div class="usuario__data-form__section1 mt-3 col-12 col-md-4">


                      <div class="form-label mt-2">
                        <label for="nombre">Ubicación</label>
                        <input [(ngModel)]="this.punto_acceso.ubicacion" class="form-control" autocomplete="off" type="text" name="ubicacion" required #inputProvForm
                         />
                      </div>
                      <div class="form-label mt-2">
                        <label for="correo">Longitud</label>
                        <input [(ngModel)]="this.longitud" class="form-control" autocomplete="off" type="text" name="longitud" required
                          #inputProvForm  />
                      </div>
                      <div class="form-label mt-2">
                        <label for="correo">Latitud</label>
                        <input [(ngModel)]="this.latitud" class="form-control" autocomplete="off" type="text" name="latitud" required
                          #inputProvForm  />
                      </div>
                      <div class="form-label mt-2">
                        <label for="correo">IP Reloj</label>
                        <input [(ngModel)]="this.punto_acceso.direccion_ip"
                        class="form-control" autocomplete="off" type="text" name="usuario" required
                          #inputProvForm  />
                      </div>
                      <div class="form-label mt-2">
                        <label for="correo">Geo-Cerca</label>
                        <input [(ngModel)]="this.punto_acceso.distancia_km"
                        class="form-control" autocomplete="off" type="number" name="usuario" placeholder="kilometros"
                          #inputProvForm  />
                      </div>


                      <div class=" row mt-2">
                        <button *ngIf="this.punto_acceso.id_reloj == 0" type="submit" class="btn btn-block btn-outline-primary">
                        GUARDAR
                      </button>
                        <button (click)="actualizar()"  *ngIf="this.punto_acceso.id_reloj !== 0" class="btn btn-block btn-outline-primary">
                        Acctualizar
                      </button>

                      </div>
                    </div>
               </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>



      </div>
    </div>

  </div>
</div>



