<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12 m-2">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">TURNOS</h2>
          <div class="col text-right mb-3">
            <button
             (click)="nuevoturno()"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#editar"
            >Agregar Turno</button>
          </div>
          <div data-toggle="modal" data-target="#editar"
           (click)="editar(turno)" class="cardo mb-3"
            *ngFor="let turno of turnos | keyvalue">

            <div class="cardo-header">
              <!-- TURNOS FILAS -->
              <div class="row ">
                <!-- BOTON -->
                <div class="col-2">
                  <button (click)="activar(turno)" class="btn btn-sm btn-danger px-2"
                    >
                    DESACTIVAR
                    <!-- <i class="ml-2 fa fa-check"></i> -->
                  </button>
                </div>
                <!-- CLAVE -->
                <div class="col">
                  <span>Clave:</span><b>{{ turno.value[0].clave }}</b>
                </div>
                <!-- TURNO -->
                <div class="col">
                  <span>Turno:</span><b>{{ turno.value[0].turno }}</b>
                </div>
                <!-- TRASLAPA -->
                <div class="col">
                  <span><b>Traslapa turno:</b></span><i *ngIf="turno.value[0].traslapa_turno == '0'"
                    class="fa fa-circle text-danger ml-2"></i>
                </div>
                <!-- ROTA -->
                <div class="col">
                  <span><b>Rota turno:</b></span><i *ngIf="turno.value[0].rota_turno == '0'"
                    class="fa fa-circle text-danger ml-2"></i>
                </div>
              </div>
              <!-- <div class="card-body">

                <table class="table">
                  <thead>
                    <tr>
                      <th>Día</th>
                      <th>Hora de Entrada</th>
                      <th>Hora de Salida</th>
                      <th>Descanso</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dia of turno.value">
                      <td>{{ dia.dia_descrip }}</td>
                      <td>{{ dia.hr_entrada }}</td>
                      <td>{{ dia.hr_salida }}</td>
                      <td> <i *ngIf="dia.descanso == '1'" class="fa fa-circle text-danger"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- MODAL EDITAR -->
<div class="modal" id="editar"  role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- HEADER -->
      <div class="modal-header">
        <h2 class="modal-title">EN ESTE</h2>
        <button #cerrarModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-body">
        <!-- TURNO -->
        <div class="row">
          <div class="col">
            <h3>Clave:
              <input [(ngModel)]="turnoSeleccionado.clave" class="form-control">
            </h3>
          </div>
          <div class="col">
            <h3>Turno:
              <input  [(ngModel)]="turnoSeleccionado.turno" class="form-control">
            </h3>
          </div>
        </div>
        <!-- TOLERANCIA  -->
        <div class="row">
          <div class="col-6 mb-2">
            <h3>Tolerancia:
            </h3>
            <input type="number" value=turnoSeleccionado.tolerancia class="form-control"  placeholder="minutos" [(ngModel)]="turnoSeleccionado.tolerancia" name="tolerancia">
          </div>
          <div class="col-6 mb-2">
            <h3>Tiempo comida:
            </h3>
            <input type="number" value=turnoSeleccionado.tiempo_comida class="form-control"  placeholder="minutos" [(ngModel)]="turnoSeleccionado.tiempo_comida" name="tiempo_comida">
          </div>

        </div>
        <!-- CHECK BOXs -->
        <div class="row">
          <div class="col">
            <h3>Traslapa turno:
              <mat-checkbox class="mt-1 ml-2" [(ngModel)]="turnoSeleccionado.traslapa_turno" name="traslapa_turno"
                (change)="updateTraslapa()"></mat-checkbox>
              </h3>
          </div>
          <div class="col">
              <mat-form-field>
                <mat-select  placeholder="Selecciona un turno" [(ngModel)]="turnoSeleccionado.id_turno_rota" name="id_turno_rota">
                  <mat-option *ngFor="let turno of turnos | keyvalue"  [value]="turno.key">{{turno.value[0].turno}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
          <div class="col">
            <h3>Rota turno:
              <mat-checkbox class="mt-1 ml-2" [(ngModel)]="turnoSeleccionado.rota_turno"
                (change)="updateRota()"></mat-checkbox>
            </h3>
          </div>
        </div>
      </div>
      <!-- TABLA -->
      <div class="col">
        <table class="table">
          <thead>
            <tr>
              <th>Día</th>
              <th>Entrada</th>
              <th>Salida</th>
              <th>Descanso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dia of turnoSeleccionado.detalle">
              <td>{{ dia.dia_descrip }}</td>
              <td>
                <input type="time" [(ngModel)]="dia.hr_entrada" class="form-control">
              </td>
              <td>
                <input type="time"  [(ngModel)]="dia.hr_salida" class="form-control">
              </td>
              <td>
                <mat-checkbox class="mt-2"
                [checked]="dia.descanso === 1"
                [(ngModel)]="dia.descanso"
                (change)="updateDescanso()"
                ></mat-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- BOTONES -->
      <div class="row text-center">
        <div class="col m-1 mb-3">
          <button  class="close px-4" data-dismiss="modal" class="btn  btn-outline-danger"
          >Cancelar</button>
        </div>
        <div *ngIf="!newTurno" class="col m-1 mb-3">
          <button (click)="actualizar()"
           class="btn btn-primary px-6"
           >Actualizar</button>
        </div>
        <div *ngIf="newTurno" class="col m-1 mb-3">
          <button  (click)="guardarNuevo()"
          class="btn btn-primary px-6">
          Guardar</button>
        </div>


      </div>

    </div>
  </div>
</div>
<!-- MODAL PRINCIPAL -->
<div class="modal" id="turnoModal2"  role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- HEADER -->
      <div class="modal-header">
        <h2 class="modal-title">{{ editando ? 'Editar turno' : 'Nuevo turno' }}</h2>
        <button #cerrarModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-body">
        <!-- TURNO -->
        <div class="row">
          <div class="col">
            <h3>Clave: <span *ngIf="!editando">{{ turnoSeleccionado.clave }}</span>
              <input *ngIf="editando" [(ngModel)]="turnoSeleccionado.clave" class="form-control">
              <input *ngIf="newTurno" [(ngModel)]="turnoSeleccionado.clave" class="form-control">
            </h3>
          </div>
          <div class="col">
            <h3>Turno: <span *ngIf="!editando">{{ turnoSeleccionado.turno }}</span>
              <input *ngIf="editando" [(ngModel)]="turnoSeleccionado.turno" class="form-control">
              <input *ngIf="newTurno" [(ngModel)]="turnoSeleccionado.turno" class="form-control">
            </h3>
          </div>
        </div>
        <!-- TOLERANCIA  -->
        <div class="row">
          <div class="col">
            <h3>Tolerancia: <span *ngIf="!editando"> {{ turnoSeleccionado.torelanciaa }} </span>
              <input type="number"  placeholder="minutos"  *ngIf="editando" [(ngModel)]="turnoSeleccionado.tolerancia" name="tolerancia">
              <input type="number"  placeholder="minutos"  *ngIf="newTurno" [(ngModel)]="turnoSeleccionado.tolerancia" name="tolerancia">
               min </h3>
          </div>
          <!-- <div class="col">
            <h3>Comida: <span *ngIf="!editando"> {{ turnoSeleccionado.tiempo_comidaa }} min</span>
              <input   type="number" *ngIf="editando" [(ngModel)]="turnoSeleccionado.tiempo_comida" name="tiempo_comida">
              <input   type="number" *ngIf="newTurno" [(ngModel)]="turnoSeleccionado.tiempo_comida" name="tiempo_comida">
            </h3>
          </div> -->
        </div>
        <!-- CHECK BOXs -->
        <div class="row">
          <div class="col">
            <h3>Traslapa turno: <span style="color: rgb(84, 84, 230);" *ngIf="editando">{{ turnoSeleccionado.traslapa_turno ? 'SI' : 'NO' }}</span>
              <mat-checkbox class="mt-1 ml-2" *ngIf="editando" [(ngModel)]="turnoSeleccionado.traslapa_turno"
                (change)="updateTraslapa()"></mat-checkbox>

              <mat-checkbox class="mt-1 ml-2" *ngIf="newTurno" [(ngModel)]="turnoSeleccionado.traslapa_turno"
                (change)="updateTraslapa()"></mat-checkbox>
              </h3>
          </div>
          <div class="col">
            <h3>Rota turno: <span style="color: rgb(84, 84, 230);" *ngIf="editando">{{ turnoSeleccionado.rota_turno ? 'SI' : 'NO' }}</span>
              <mat-checkbox class="mt-1 ml-2" *ngIf="editando" [(ngModel)]="turnoSeleccionado.rota_turno"
                (change)="updateRota()"></mat-checkbox>
              <mat-checkbox class="mt-1 ml-2" *ngIf="newTurno" [(ngModel)]="turnoSeleccionado.rota_turno"
                (change)="updateRota()"></mat-checkbox>
            </h3>
          </div>
        </div>
      </div>
      <!-- TABLA -->
      <div class="col">
        <table class="table">
          <thead>
            <tr>
              <th>Día</th>
              <th>Entrada</th>
              <th>Salida</th>
              <th>Descanso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dia of turnoSeleccionado.detalle">
              <td>{{ dia.dia_descrip }}</td>
              <td>
                <span *ngIf="editando">{{ dia.hr_entrada }}</span>
                <input type="time" *ngIf="editando" [(ngModel)]="dia.hr_entrada" class="form-control">
                <input type="time" *ngIf="newTurno" [(ngModel)]="dia.hr_entrada" class="form-control">
              </td>
              <td>
                <span *ngIf="editando">{{ dia.hr_salida }}</span>
                <input type="time" *ngIf="editando" [(ngModel)]="dia.hr_salida" class="form-control">
                <input type="time" *ngIf="newTurno" [(ngModel)]="dia.hr_salida" class="form-control">
              </td>
              <td>
                <mat-checkbox class="mt-2" *ngIf="editando"
                [(ngModel)]="dia.descanso"
                (change)="updateDescanso()"
                ></mat-checkbox>
                <mat-checkbox class="mt-2" *ngIf="newTurno"
                [(ngModel)]="dia.descanso"
                (change)="updateDescanso()"
                ></mat-checkbox>
                <i *ngIf="dia.descanso == '1' && editando == false" class="fa fa-circle text-danger"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- BOTONES -->
      <div class="row text-center">
        <div class="col m-1 mb-3">
          <button  class="close px-4" data-dismiss="modal" class="btn  btn-outline-danger"
          >Cancelar</button>
        </div>

        <div *ngIf="newTurno" class="col m-1 mb-3">
          <button  (click)="guardarNuevo()"
          class="btn btn-primary px-6">
          Guardar</button>
        </div>


      </div>

    </div>
  </div>
</div>
