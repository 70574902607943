<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">FESTIVOS</h4>
          <div class="row  justify-content-between">
            <div class="col">
              <mat-form-field>
                <mat-label>Seleccionar Año</mat-label>
                <mat-select [(ngModel)]="selectedYear">
                  <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <button  (click)="nuevo()" class="btn btn-outline-info" data-toggle="modal" data-target="#festivoModal">Agregar día festivo</button>
            </div>

          </div>

          <!-- TABLA -->
          <div id="tabla" class="card-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                <table mat-table [dataSource]="dataSource" class="w-100 custom-table">

                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef>FECHA</th>
                    <td mat-cell *matCellDef="let element"> {{element.fecha | date: 'dd/MM/yyyy'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
                    <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" data-toggle="modal" data-target="#editarModal"  (click)="openEditarModal(row)"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- MODAL AGREGAR FESTIVO -->
<div class="modal" id="festivoModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Agregar día festivo</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-form-field class="col-10 offset-1">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="Seleccionar fecha" [(ngModel)]="festivo.fecha">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-10 offset-1">
          <mat-label>Descripción</mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="7"
                    [(ngModel)]="festivo.descripcion"
                    name="descripcion">
          </textarea>
        </mat-form-field>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="cerrar1">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="guadarfestivos()">Guardar</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL EDITAR-->
<div class="modal" id="editarModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Editar día festivo</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-form-field class="col-10 offset-1">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker2" placeholder="Seleccionar fecha" name="fecha"
          [(ngModel)]="festivo.fecha"
          >
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-10 offset-1">
          <mat-label>Descripción</mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="2"
                    [(ngModel)]="festivo.descripcion"
                    >
          </textarea>
        </mat-form-field>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="cerrar">Cerrar</button>
        <button  type="button" class="btn btn-primary" (click)="editarfestivos()" >Guardar</button>
      </div>
    </div>
  </div>
</div>





