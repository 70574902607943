import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema_bitacora',
  templateUrl: './sistema_bitacora.component.html',
  styleUrls: ['./sistema_bitacora.component.css']
})
export class SistemaControlComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
