<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
    <div class="row">
        <div class="col">
            <div class="card border-info">
                <div class="card-header">
                    CATÁLOGO DE BANCOS
                </div>
                <!-- SE CREA UNA TARJETA DE TODA LA FORMA-->
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-lg-4 col-sm-12 mb-2">
                            <div class="buscador">
                                <mat-form-field class="w-100">
                                    <mat-label>Buscar Bancos</mat-label>
                                    <input #input type="text" placeholder="Buscar..." (keyup)="busqueda(input.value)" aria-label="Buscar Bancos" matInput [formControl]="myControl" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getBancos($event)'>
                                        <mat-option *ngFor="let option of bancos_busqueda" [value]="option.descripcion" [id]="option.id_catbanco+''">
                                            {{option.descripcion}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">

                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12" style="text-align: end;">
                            <a class="btn btn-info" (click)="guardar()" title="Nuevo Cuenta Bancaria"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                        </div>
                    </div>
                    <!-- Aqui Inicia la tabla de los datos-->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">No. banco</th>
                                    <th scope="col">Cuenta</th>
                                    <th scope="col" class="text-center">Clabe</th>
                                    <th scope="col" class="text-center">Banco</th>
                                    <th scope="col" class="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="band; else pintaVacio" class="">
                                <tr *ngFor="let banco of bancos">
                                    <td colspan="1" style="vertical-align: middle;" class="text-center">{{banco.id_catbanco}}</td>
                                    <td colspan="1" style="vertical-align: middle;">{{banco.cuenta}}</td>
                                    <td colspan="1" style="vertical-align: middle;" class="text-center">{{banco.clabe}}</td>
                                    <td colspan="1" style="vertical-align: middle;" class="text-center">{{banco.descripcion}}</td>
                                    <td colspan="1" style="width: 20%;" class="text-center">
                                        <a title="Editar" (click)="editar(banco.id_catbanco)" style="cursor: pointer;" class="btn btn-yellow btn-sm"><img src="./assets/iconos/edit.svg" style="width: 14px;"></a>
                                    </td>
                                </tr>
                            </tbody>
                            <ng-template #pintaVacio>
                                <tbody>
                                    <tr>
                                        <td colspan="6" class="text-center">No existen cuentas bancarias</td>
                                    </tr>
                                </tbody>
                            </ng-template>
                            <tbody></tbody>
                        </table>
                        <div class="filtros container mt-3">
                            <div class="row">
                                <div class="col-lg-5 col-sm-12"></div>
                                <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                                    <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                        <ul class="pagination">
                                            <li class="page-item" *ngIf="previous">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                    <span class="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                            <li class="page-item" *ngIf="next">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                    <span class="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div class="col-lg-2 col-sm-12" style="text-align: end;">
                                    <mat-form-field class="w-100">
                                        <mat-label>No. resultados</mat-label>
                                        <mat-select [(ngModel)]="taken" (selectionChange)="traerBancos()">
                                            <mat-option [value]="5">5</mat-option>
                                            <mat-option [value]="10">10</mat-option>
                                            <mat-option [value]="20">20</mat-option>
                                            <mat-option [value]="30">30</mat-option>
                                            <mat-option [value]="40">40</mat-option>
                                            <mat-option [value]="50">50</mat-option>
                                            <mat-option [value]="100">100</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Termina la tabla de datos-->
                </div>


            </div>
        </div>
    </div>
</div>

<!-- MODAL DE ALTA DE -->