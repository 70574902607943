import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema_facturacion',
  templateUrl: './sistema_facturacion.component.html'
})
export class SistemaFacturacionComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}