<nav class="navbar navbar-top navbar-expand-md navbar-dark {{color}}" id="navbar-main">
  <div class="container-fluid">
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item m-auto">
        <span class="mb-0 text-m text-white font-weight-bold">{{nombre_empresa}}</span>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto mt-2">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="{{url_foto}}">
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold pr-6">{{nombre}}</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bienvenido!</h6>
          </div>
          <a *ngIf="empresas.length" class="dropdown-item">
            <i class="ni ni-curved-next"></i>
            <span (click)="openModal()">Cambiar de empresa</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="{{color}}" style="height: 250px;"></div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-xl-4 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Empleados activos</h5>
              <span class="h3 font-weight-bold mb-0">3</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                <i class="fas fa-users"></i>
              </div>
            </div>
            <!-- <p class="mt-3 mb-0 text-muted text-sm">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Tipo de nómina</h5>
              <span class="h3 font-weight-bold mb-0">{{header[1]}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                <i class="far fa-hand-pointer"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 3.48%</span>
            <span class="text-nowrap">Since last week</span>
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Periodo de ejercicio</h5>
              <span class="h3 font-weight-bold mb-0">{{periodo}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                <i class="far fa-calendar-check"></i>
              </div>
            </div>
          </div>
          <!-- <p class="mt-3 mb-0 text-muted text-sm">
            <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
            <span class="text-nowrap">Since yesterday</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h4 *ngIf="tipo_arreglo == 0" class="modal-title" id="modal-basic-title">¿Que empresa desea utilizar?</h4>
    <h4 *ngIf="tipo_arreglo == 1" class="modal-title" id="modal-basic-title">Seleccione el tipo de nomina</h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-row">
        <div class="form-group col-md-12">
          <div *ngIf="tipo_arreglo == 0" class="list-group">
            <a *ngFor="let empresa of empresas" (click)="eleccion(empresa.id_empresa)" style="cursor: pointer;" class="list-group-item list-group-item-action {{empresa.class}}" aria-current="true">
              {{empresa.empresa}}
            </a>
          </div>
          <div *ngIf="tipo_arreglo == 1" class="list-group">
            <a *ngFor="let nomina of nominas" (click)="redireccionNomina(nomina.id_nomina)" style="cursor: pointer;" class="list-group-item list-group-item-action" aria-current="true">
              {{nomina.nomina}}
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-warning" aria-label="Close" (click)="d('Cross click')">Cancelar</button>
  </div>
</ng-template>