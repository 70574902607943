<div class="header " style="height: 250px;">
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col">
      <div class="card border-info">
        <div class="card-header">
          Revisar XML
        </div>
        <div class="card-body">
          <div class="container-fluid">

            <div class="row">
              <!-- BUSCAR EMPRESA -->
              <div class="col-sm-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>BUSCAR EMPRESA</mat-label>
                  <input type="text" placeholder="Ingrese el nombre o id de la empresa" aria-label="Number" matInput
                    (keyup)="buscarEmpresa()" [formControl]="filterControlEmpresa" [matAutocomplete]="auto"
                    style="text-transform: uppercase;">
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)"(optionSelected)="nomEmpresa($event)">
                    <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.nombre"
                      [id]="empresa.id_empresa" >
                      {{empresa.empresa}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <!-- SELECIONAR EJERCICIO -->
              <div class="col-sm-12 col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>EJERCICIO</mat-label>
                  <mat-select [(ngModel)]="ejercicioActual" (selectionChange)="ejercicioSeleccionado($event)">
                    <mat-option *ngFor="let ejersel of ejercicio"
                      [value]="ejersel.ejercicio">{{ejersel.ejercicio}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!--PERIODO-->
              <div class="col-sm-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>PERIODO</mat-label>
                  <input matInput type="number" [(ngModel)]="periodoActual" (input)="onPeriodChange()" />
                </mat-form-field>
              </div>
              <!-- MES DE PAGO -->
              <div class="col-sm-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>MES</mat-label>
                  <mat-select [(ngModel)]="mesActual" (selectionChange)="mesSeleccionado($event.value)">
                    <mat-option *ngFor="let messel of meses" [value]="messel.Id">{{messel.meses}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- SELECIONAR BIMESTRE -->
              <div class="col-sm-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>BIMESTRE</mat-label>
                  <mat-select [(ngModel)]="biMesActual" (selectionChange)="bimesSeleccionado($event.value)">
                    <mat-option *ngFor="let bimessel of bimestres" [value]="bimessel.Id">{{bimessel.meses}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- RFC TRABAJADOR -->
              <div class="col-sm-12 col-lg-3">
                <mat-form-field class="w-100">
                  <mat-label>RFC TRABAJADOR</mat-label>
                  <input matInput type="text" [(ngModel)]="rfcTrabajador" (input)="onRFCChange()" />
                </mat-form-field>
              </div>
              <!-- BOTONES -->
              <div class="text-center col-lg-12 col-sm-12">
                <button (click)="trabajadoresXML()" class="btn btn-success" title="Buscar">
                  Buscar <i class="fa fa-search"
                  aria-hidden="true"></i></button>
                  <span style="margin: 0 20px;"></span>
                  <button *ngIf="loading" class="btn btn-outline-info" title="Descarga segura" (click)="exportarAPDF()">
                    Descargar PDF <i class="fa fa-download "
                    aria-hidden="true"></i></button>
              </div>
              <hr>
              <!-- TABLA DE TRABAJADORES  -->
              <div #tablaParaPDF *ngIf="loading" class=" mt-2 col-sm-12 col-lg-12 text-center">
                <h1 id="tituloTabla"></h1>
                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100,500,1000]" [pageSize]="10" [showFirstLastButtons]="true"></mat-paginator>
                <!-- TABLA DE RESULTADOS -->
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 col">
                  <!-- Columnas de la tabla -->
                  <ng-container matColumnDef="num_empleado">
                    <th mat-header-cell *matHeaderCellDef>Núm. Empleado</th>
                    <td mat-cell *matCellDef="let row">{{ row.num_empleado }}</td>
                  </ng-container>

                  <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let row">{{ row.nombre }}</td>
                  </ng-container>

                  <ng-container matColumnDef="rfc">
                    <th mat-header-cell *matHeaderCellDef>RFC</th>
                    <td mat-cell *matCellDef="let row">{{ row.rfc }}</td>
                  </ng-container>

                  <ng-container matColumnDef="periodo">
                    <th mat-header-cell *matHeaderCellDef>Periodo</th>
                    <td mat-cell *matCellDef="let row">{{ row.periodo }}</td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_inicial_pago">
                    <th mat-header-cell *matHeaderCellDef>Fecha Inicial</th>
                    <td mat-cell *matCellDef="let row">{{ row.fecha_inicial_pago }}</td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_final_pago">
                    <th mat-header-cell *matHeaderCellDef>Fecha final</th>
                    <td mat-cell *matCellDef="let row">{{ row.fecha_final_pago }}</td>
                  </ng-container>

                  <ng-container matColumnDef="uuid">
                    <th mat-header-cell *matHeaderCellDef>UUID</th>
                    <td mat-cell *matCellDef="let row">{{ row.uuid }}</td>
                  </ng-container>

                  <!-- Columna de acciones -->
                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef>Acciones</th>
                    <td mat-cell *matCellDef="let row">
                      <button class="btn btn-success" [routerLink]="['/contabilidad/edit-trab']" [queryParams]="{ id_boveda: row.id_boveda }" >Editar</button>
                    </td>
                  </ng-container>

                  <!-- Define las columnas a mostrar -->
                  <tr mat-header-row *matHeaderRowDef="columnas"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnas;"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
