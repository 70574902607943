<div class="header " style="height: 250px;"></div>
<div class="container-fluid mt--9">
<div class="row">
  <div class="col">
    <div class="card border-danger">
        <div class="card-header">
          Graficas
        </div>
      <div class="card-body">
          <div class="container">
            <div class="row">
             <!-- BUSCAR EMPRESA -->
             <div class="col-sm-12 col-lg-5">
              <mat-form-field class="w-100">
                <mat-label>BUSCAR EMPRESA</mat-label>
                <input type="text" placeholder="Ingrese el nombre o id de la empresa" aria-label="Number" matInput
                  (keyup)="buscarEmpresa()" [formControl]="filterControlEmpresa" [matAutocomplete]="auto"
                  style="text-transform: uppercase;">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)"(optionSelected)="nomEmpresa($event)">
                  <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.nombre"
                    [id]="empresa.id_empresa" >
                    {{empresa.empresa}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <!-- EJERCICIO -->
            <div class="col-4 col-lg-4">
              <mat-form-field class="w-100">
                <mat-label>EJERCICIO</mat-label>
                <mat-select [(ngModel)]="ejercicioActual" (selectionChange)="ejercicioSeleccionado($event)">
                  <mat-option *ngFor="let ejersel of ejercicio" [value]="ejersel.ejercicio">{{ejersel.ejercicio}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>
          </div>
        <!-- GRAFICOS -->
        <div *ngIf="this.graficas" class="container-fluid mt-3">
          <div class="row">
            <!-- TABLA DE EMPRESAS -->
            <!-- <div class="col-12 mt-2 mx-0 py-0">
              <div class="card shadow">
                  <div class="table-responsive"  style="height: 250px; overflow-y: scroll;">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <tr class="text-center">
                        <th scope="col" class="text-left">Empresa</th>
                        <th scope="col">Facturas</th>
                        <th scope="col">Pagadas</th>
                        <th scope="col">No pagadas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let empresa of empresas" class="text-center" style="cursor: pointer;" (click)="pintarChart(empresa.id_empresa)">
                        <td class="text-left">{{empresa.empresa}}</td>
                        <td>{{empresa.facturas}}</td>
                        <td>{{empresa.pagadas}}</td>
                        <td>{{empresa.por_pagar}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->
            <div class="col-12 mt-2 mx-0 py-0">
              <div class="row">
                <div class="col-5">
                  <div class="card shadow h-100">
                    <div class="card-header bg-transparent">
                        <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-uppercase text-light ls-1 mb-1">GRAFICOS 1</h6>
                            <h2 class="text-white mb-0"></h2>
                        </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Chart -->
                        <canvas baseChart
                          [datasets]="doughnutChartData"
                          [labels]="doughnutChartLabels"
                          [options]="barChartOptions"
                          [plugins]="barChartPlugins"
                          [legend]="barChartLegend"
                          chartType="doughnut"
                          [colors]="chartColors">
                        </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <div class="card shadow">
                    <div class="card-header bg-transparent">
                        <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-uppercase text-light ls-1 mb-1">GRAFICOS 2</h6>
                            <h2 class="text-white mb-0"></h2>
                        </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Chart -->
                        <canvas baseChart
                          [datasets]="barChartData"
                          [labels]="barChartLabels"
                          [options]="barChartOptions"
                          [plugins]="barChartPlugins"
                          [legend]="barChartLegend"
                          chartType="bar"
                          [colors]="chartColors">
                        </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>




