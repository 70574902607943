<div class="header {{color}}" style="height: 250px;">
    <div class="container-fluid">
    </div>
</div>
<div class="container-fluid mt--9">
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <div class="card">
                <div class="card-header">
                  <h4 class="card-title">SERIES</h4>
                  <div class="card-category">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>Seleccionar empresa</mat-label>
                                <input type="text"
                                        placeholder="Ingrese el nombre o id de la empresa"
                                        aria-label="Number"
                                        matInput
                                        (keyup)="buscarEmpresa()"
                                        [formControl]="filterControlEmpresa"
                                        [matAutocomplete]="auto"
                                        style="text-transform: uppercase;">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionEmpresa($event)">
                                    <mat-option *ngFor="let empresa of empresas_busqueda" [value]="empresa.empresa" [id]="empresa.id_empresa">
                                        {{empresa.empresa}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                        </div>
                        <div class="col-lg-4 col-sm-12 text-right">
                            <button class="btn btn-info" title="Nueva serie" (click)="nuevaSerie()"><i class="fas fa-plus-square"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12" style="overflow-x: scroll;">
                            <table *ngIf="dataSource.data.length > 0; else pintaElse" mat-table [dataSource]="dataSource" class="w-100">
                                
                                <ng-container matColumnDef="Id">
                                    <th mat-header-cell *matHeaderCellDef> Id </th>
                                    <td mat-cell *matCellDef="let element"> {{element.id_serie}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="Nombre">
                                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.serie}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="Direccion">
                                    <th mat-header-cell *matHeaderCellDef> Direccion </th>
                                    <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
                                </ng-container>
    
                                <ng-container matColumnDef="Accion">
                                    <th mat-header-cell *matHeaderCellDef> Accion </th>
                                    <td mat-cell *matCellDef="let element">  
                                        <button [matMenuTriggerFor]="menu" class="btn btn-info btn-sm"><i class="fas fa-ellipsis-v"></i></button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="visualizar(element.id_serie)">
                                                <mat-icon><i class="far fa-eye"></i></mat-icon>
                                                <span>Ver</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>
        
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-paginator 
                                [pageSizeOptions]="[5, 10, 20]"
                                showFirstLastButtons 
                                aria-label="Select page of periodic elements">
                            </mat-paginator>
                            <ng-template #pintaElse>
                                <p class="text-center">Aún no se tiene series en está empresa</p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 *ngIf="tipo_modal == 1" class="modal-title" id="tituloModal">Nueva serie</h2>
        <h2 *ngIf="tipo_modal == 2" class="modal-title" id="tituloModal">Visualizar serie</h2>
    </div>
    <div class="modal-body">
        <div class="container mt-3">
            <div class="row">
                <div class="col-lg-10 col-sm-12">
                    <mat-form-field class="w-100">
                      <mat-label>Serie</mat-label>
                      <input matInput [(ngModel)]="serie.serie" name="serie" style="text-transform: uppercase;">
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-sm-12" *ngIf="tipo_modal == 1">
                  <mat-form-field class="w-100">
                    <mat-label>No. Folio</mat-label>
                    <input matInput [(ngModel)]="serie.no_folio" name="no_folio" style="text-transform: uppercase;">
                  </mat-form-field>
                </div>
                <div class="col-lg-2 col-sm-12" *ngIf="tipo_modal == 2">
                  <mat-form-field class="w-100">
                    <mat-label>No. Folio</mat-label>
                    <input matInput [(ngModel)]="serie.no_folio" name="no_folio" style="text-transform: uppercase;" disabled>
                  </mat-form-field>
                </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Calle</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.calle" name="calle" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Num. interior</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.numero_interior" name="ni" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Num. exterior</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.numero_exterior" name="ne" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Cruzamientos</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.cruzamiento_uno" name="cruza" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Colonia</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.colonia" name="colonia" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>C.P</mat-label>
                  <input matInput #postalCode maxlength="5" [(ngModel)]="serie.direccion.codigo_postal">
                  <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Buscar estado</mat-label>
                  <input type="text"
                          placeholder="Ingrese del estado"
                          aria-label="Number"
                          matInput
                          (keyup)="buscarEstado()"
                          [formControl]="filterControlEstado"
                          [matAutocomplete]="autodos"
                          style="text-transform: uppercase;">
                  <mat-autocomplete #autodos="matAutocomplete" (optionSelected)="optionEstado($event)">
                      <mat-option *ngFor="let estado of estados_busqueda" [value]="estado.estado" [id]="estado.id_estado">
                          {{estado.estado}}
                      </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Localidad</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.localidad" name="loca" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Municipio</mat-label>
                  <input matInput [(ngModel)]="serie.direccion.municipio" name="muni" style="text-transform: uppercase;">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <mat-form-field class="w-100">
                  <mat-label>Referencias</mat-label>
                  <textarea matInput placeholder="Redacte una breve referencia de la empresa" [(ngModel)]="serie.direccion.descripcion" name="ref" style="text-transform: uppercase;"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_modal == 1" type="button" class="btn btn-success" (click)="altaSerie()">Guardar</button>
        <button *ngIf="tipo_modal == 2" type="button" class="btn btn-success" (click)="modificarSerie()">Guardar</button>
    </div>
</ng-template>