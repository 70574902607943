import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema-admin',
  templateUrl: './sistema-admin.component.html',
  styleUrls: ['./sistema-admin.component.css']
})
export class SistemaAdminComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
