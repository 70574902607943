<div class="header {{color}}" style="height: 250px;">
  <div class="container-fluid">
  </div>
</div>
<div class="container-fluid mt--9">
  <div class="row">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">INCAPACIDADES</h4>
          <!-- BUSCADOR -->
          <div class="card-category">
            <div class="row">
              <div class="col-md-6 col-sm-12 d-flex align-items-center">
                <mat-form-field class="w-100 px-1">
                  <mat-label class="d-flex justify-content-between align-items-center">Buscar trabajador <i
                      class="fa fa-search" aria-hidden="true"></i></mat-label>
                  <input type="text" placeholder="NOMBRE o RFC" aria-label="Number" matInput (keyup)="buscarCandidato()"
                    [formControl]="filterControl" [matAutocomplete]="auto" style="text-transform: uppercase;">
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionUsuario($event)">
                    <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                      [id]="empleado">
                      {{empleado.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <button class="btn btn-sm btn-success"><i class="fa fa-search" aria-hidden="true"></i></button> -->
              </div>
              <div class="col">
                <button (click)="openModal2()" class="btn btn-primary"><i class="fa fa-plus" aria-hidden="true"></i> Agregar incapacidad</button>
              </div>
            </div>
          </div>
        </div>
        <!-- CARDS -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 mb-3" *ngFor="let incapacidad of incapacidades">
              <mat-card class="custom-card" >
                <mat-card-content>
                  <div class="row">
                    <div class="col">
                   <img [src]=fotoIncapacidad alt="Imagen"   class="card-image border ">
                 </div>
                 <div (click)="openModal(incapacidad)" style="cursor: pointer;" title="¿EDITAR?" class="col text_info">
                      <p class="mb-0 nomobre">{{incapacidad.nombre_completo}}</p>
                      <p class="card-label mb-0" style="text-transform: uppercase;" ><strong>Folio:</strong>{{incapacidad.folio}}</p>
                      <p class=" mb-0 badge badge-success">Autorizado</p>
                      <p class=" mb-0 card-label"><strong>Dias:</strong> {{incapacidad.dias_incapacidad}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p class="card-label">Fecha Inicio: <br> <strong>{{incapacidad.fecha_inicial | date: 'dd/MM/yyyy' }}</strong></p>
                    </div>
                    <div class="col">
                      <p class="card-label">Fecha Final: <br> <strong>{{incapacidad.fecha_final | date: 'dd/MM/yyyy'}}</strong></p>
                    </div>
                  </div>
                  <div class="col">
                    <button title="comprobante" (click)="togglePhotosModal()" type="button" class=" btn-sm btn btn-outline-primary px-3"
                    data-mdb-ripple-color="dark" >Comprobante</button>
                  </div>


                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- MODAL AGREGAR INCAPACIODAD -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h1>{{ editar ? 'Editar Incapacidad' : 'Agregar Incapacidad' }}</h1>
      </div>
      <form class="container">
          <div class="card-category">
            <div class="row">
              <div class="col-md-6 col-sm-12 d-flex align-items-center">
                <h4 *ngIf="editar">{{incapacidad.nombre_completo}}</h4>
                <mat-form-field *ngIf="!editar" class="w-100 px-1">
                  <mat-label class="d-flex justify-content-between align-items-center">Buscar trabajador <i
                      class="fa fa-search" aria-hidden="true"></i></mat-label>
                  <input type="text" placeholder="NOMBRE o RFC" aria-label="Number" matInput (keyup)="buscarCandidato()"
                    [formControl]="filterControl" [matAutocomplete]="auto" style="text-transform: uppercase;">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let empleado of objEmpleados" [value]="empleado.nombre"
                      [id]="empleado.id_empleado">
                      {{empleado.nombre}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="example-full-width">
                  <input autocomplete="none" [(ngModel)]="incapacidad.folio"  style="text-transform: uppercase;" name="folio" matInput placeholder="Folio">
                </mat-form-field>
              </div>
            </div>
          </div>
        <div class="row">



          <div class="col-md-4">
            <mat-form-field>
              <input  [(ngModel)]="incapacidad.fecha_inicial " name="fecha_inicial"  matInput [matDatepicker]="picker" placeholder="Fecha Inicio" (ngModelChange)="formatearFecha()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input [(ngModel)]="incapacidad.dias_incapacidad" name="dias_incapacidad" matInput placeholder="Incapacidad" type="number" class="example-right-align" (input)="calcularFF()">
              <!-- <span matPrefix>$&nbsp;</span> -->
              <span matSuffix>Días</span>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input   [(ngModel)]="incapacidad.fecha_final" name="fecha_final" matInput [matDatepicker]="picker2" placeholder="Fecha Final">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-md-12 d-flex justify-content-end">
            <button (click)="closeModal()" class="btn btn-secondary">Cancelar</button>
            <button  (click)="guardarIncapacidad()"  type="submit"t class="btn btn-primary">Guardar</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</ng-template>

<!-- MODAL -->
<!-- <ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body" style="overflow-x: hidden;">
    <div class="modal-content">
      <div class="col text-center">
        <h2>Nombre del trabajador</h2>
      </div>
      <div class="col">
        <h3>Fecha: {{ selectedRowData.fecha }}</h3>
        <form class="mt-2 example-form">
          <mat-form-field class="col-10 offset-1">
            <mat-label>Justificación</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="7"></textarea>
          </mat-form-field>
        </form>
      </div>
      <div class="col d-flex justify-content-end m-2">
        <button class="btn btn-success" (click)="closeModal()">Guardar</button>
        <button class="btn btn-danger" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</ng-template> -->


<!-- MODAL PARA SUBIR FOTOS DEL USUARIO -->
<div class="usuario-modal" *ngIf="extraModal">
  <div class="usuario-modal__extra">
    <!-- <button class="usuario-modal__close icon-btn" (click)="togglePhotosModal()">
      <i i class="fa fa-times"></i>
    </button> -->
    <!-- TÍTULO -->
    <h3 class="extras-title title">Comprobante Incapacidad</h3>

    <!-- CUERPO DEL MODAL -->
    <div class=" row usuario-modal__extra-container mt-3">
      <!-- IMAGEN PRINCIPAL -->
      <div class=" col-6 usuario-modal__extra-image">
        <img
        [src]=fotoIncapacidad
          alt="placeholder"
          *ngIf="mainImage == ''"
        />
        <img
        [src]="mainImage"
        alt="imagen principal"
        *ngIf="mainImage !== ''"
        />
      </div>
      <!-- BOTÓN PARA AÑADIR FOTO -->
      <div class=" upload-buttons">
        <button class="btn btn-outline-info" (click)="subirImagen()">
          Subir imagen
        </button>
        <input type="file" #file_input id="foto_user" style="display: none;" accept=".png,.jpg" (change)="cambiarImagen($event)">
        <!-- BOTÓN PARA TOMAR FOTO -->
        <button  class="btn btn-outline-primary" (click)="openModalCamera()">Tomar foto</button>
        <button class="btn btn-outline-danger" (click)="togglePhotosModal()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #modal_camera let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h2 class="modal-title" id="tituloModal">Tomar fotografia</h2>
  </div>
  <div class="modal-body">
      <div class="container">
          <div class="row">
              <div *ngIf="isCameraExist; else noCameraExist" class="col-12">
                  <div class="border border-success rounded text-center" style="width: 100%;height: 265px;">
                      <webcam [height]="450" [width]="350" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                          *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
                  </div>

                  <div *ngIf="errors.length > 0">
                      <h4>Error Messages:</h4>
                      <ul *ngFor="let error of errors">
                          <li>{{ error | json }}</li>
                      </ul>
                  </div>
              </div>

              <ng-template #noCameraExist>
                  <div class="border border-danger rounded text-center"
                  style="display: flex;justify-content: center;align-items: center;width: 450px;height: 350px;">
                      Este dispositivo no cuenta con una camara disponible
                  </div>
              </ng-template>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-warning" (click)="cerrarModalCamera()">Cerrar</button>
      <button *ngIf="isCameraExist" type="button" class="btn btn-primary" (click)="takeSnapshot()">Tomar foto</button>
  </div>
</ng-template>
