import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sistema-reclutamiento',
  templateUrl: './sistema-reclutamiento.component.html',
  styleUrls: ['./sistema-reclutamiento.component.css']
})
export class SistemaReclutamientoComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
