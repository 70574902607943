<div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow" style="height: 550px;">
          <div class="card-header border-0">
              <div class="row align-items-center">
                  <div class="col">
                  <h3 class="mb-0">CATÁLOGO DE PERIODOS</h3>
              </div>
          </div>
          <div class="card-body">
              <div class="row mb-2">
                  <div class="col-lg-4 col-sm-12 mb-2">
                      <div class="buscador">
                          <mat-form-field class="w-100">
                              <mat-label>Buscar por ejercicio</mat-label>
                              <input #input
                                     type="text"
                                     placeholder="Buscar..."
                                     (keyup)="busqueda(input.value)"
                                     aria-label="Buscar ejercicio"
                                     matInput
                                     [formControl]="myControl"
                                     [matAutocomplete]="auto">
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getPeriodo($event)'>
                                <!-- <mat-option *ngFor="let option of departamentos_busqueda" [value]="option.departamento" [id]="option.id_departamento+''">
                                  {{option.departamento}}
                                </mat-option> -->
                              </mat-autocomplete>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="col-lg-4 col-sm-12">
                    
                  </div>
                  <div class="col-lg-4 col-sm-12" style="text-align: end;">
                      <a class="btn btn-info" (click)="guardar()" title="Nuevo periodo"><img src="./assets/iconos/agregar.svg" style="width: 24px;"></a>
                  </div>
              </div>
              <div class="table-responsive">
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th scope="col" class="text-center">No. ejercicio</th>
                              <th scope="col" class="text-center">Nómina</th>
                              <th scope="col" class="text-center">Ejercicio</th>
                              <th scope="col" class="text-center">Fecha inicial</th>
                              <th scope="col" class="text-center">Fecha final</th>
                              <th scope="col" class="text-center">Acciones</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="periodos.length > 0; else pintaVacio" class="">
                          <tr *ngFor="let periodo of periodos" class="text-center">
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{periodo.id_periodo}}</td>
                              <td colspan="1" style="vertical-align: middle;">{{periodo.nomina}}</td>
                              <td colspan="1" style="vertical-align: middle;">{{periodo.ejercicio}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{periodo.fecha_inicial}}</td>
                              <td colspan="1" style="vertical-align: middle;" class="text-center">{{periodo.fecha_final}}</td>
                              <td colspan="1" style="width: 20%;" class="text-center">
                                  <a title="Editar" (click)="editar(periodo.id_real)" style="cursor: pointer;" class="btn btn-yellow btn-sm"><img src="./assets/iconos/edit.svg" style="width: 15px;"></a>
                              </td>
                          </tr>
                      </tbody>
                      <ng-template #pintaVacio>
                          <tbody>
                              <tr class="text-center">
                                  <td colspan="6">No se cuenta con ejercicios actualmente</td>
                              </tr>
                          </tbody>
                      </ng-template>
                  </table>
                  <div class="filtros container mt-3">
                      <div class="row">
                          <div class="col-lg-5 col-sm-12"></div>
                          <div class="col-lg-5 col-sm-12" style="text-align: center;display: flex;justify-content: center; align-items: center;">
                                <!-- <nav *ngIf="mostrar_pagination" aria-label="Page navigation example">
                                    <ul class="pagination">
                                    <li class="page-item" *ngIf="previous">
                                        <a class="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li *ngFor="let pagina of paginas" class="page-item {{pagina.active}}" (click)="irPagina(pagina.valor_pagina)"><a class="page-link" style="cursor: pointer;">{{pagina.numero}}</a></li>
                                    <li class="page-item" *ngIf="next">
                                        <a class="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Next</span>
                                        </a>
                                    </li>
                                    </ul>
                                </nav> -->
                          </div>
                          <div class="col-lg-2 col-sm-12" style="text-align: end;">
                              <mat-form-field class="w-100">
                                  <mat-label>No. resultados</mat-label>
                                  <mat-select [(ngModel)]="taken" (selectionChange)="mostrarPeriodos()">
                                      <mat-option [value]="5">5</mat-option>
                                      <mat-option [value]="10">10</mat-option>
                                      <mat-option [value]="20">20</mat-option>
                                      <mat-option [value]="30">30</mat-option>
                                      <mat-option [value]="40">40</mat-option>
                                      <mat-option [value]="50">50</mat-option>
                                      <mat-option [value]="100">100</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<ng-template #content let-c="close" let-d="dismiss" class="modal-lg">
    <div class="modal-header">
        <h2 *ngIf="tipo_modal == 1" class="modal-title" id="tituloModal">Nuevo periodo</h2>
        <h2 *ngIf="tipo_modal == 2" class="modal-title" id="tituloModal">Editar periodo</h2>
    </div>
    <div class="modal-body">
        <div class="form">
            <div class="form row">
                <div class="form-group col-lg-4 col-sm-12">
                    <mat-form-field *ngIf="tipo_modal == 1" class="w-100">
                        <mat-label>Seleccione el ejercicio</mat-label>
                        <mat-select [(ngModel)]="anio">
                            <mat-option *ngFor="let anio_option of anios" [value]="anio_option">{{anio_option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="tipo_modal == 2" class="w-100">
                        <mat-label>Seleccione el ejercicio</mat-label>
                        <mat-select [(ngModel)]="anio" disabled>
                            <mat-option *ngFor="let anio_option of anios" [value]="anio_option">{{anio_option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-4 col-sm-12">
                    <mat-form-field *ngIf="tipo_modal == 1" class="w-100">
                        <mat-label>Tipo de nómina</mat-label>
                        <mat-select [(ngModel)]="tipo_nomina"> 
                            <mat-option *ngFor="let tipo of nominas" [value]="tipo.id_nomina">{{tipo.nomina}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="tipo_modal == 2" class="w-100">
                        <mat-label>Tipo de nómina</mat-label>
                        <mat-select [(ngModel)]="tipo_nomina" disabled> 
                            <mat-option *ngFor="let tipo of nominas" [value]="tipo.id_nomina">{{tipo.nomina}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group col-lg-4 col-sm-12">
                    <button class="btn btn-info btn-sm mt-3" (click)="generarEjercicio()">Generar</button>
                </div>
            </div>
            <div class="form-row">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#periodo" role="tab" aria-controls="home" aria-selected="true">Periodos</a>
                    </li>
                    <!-- <li class="nav-item">
                      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#importar" role="tab" aria-controls="profile" aria-selected="false">Importar</a>
                    </li> -->
                  </ul>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="periodo" role="tabpanel" aria-labelledby="home-tab">
                            <div class="table-responsive" style="height: 400px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="col">Mes</th>
                                            <th colspan="col">Fecha inicial</th>
                                            <th colspan="col">Fecha final</th>
                                            <th colspan="col">Actual</th>
                                            <th colspan="col">Cerrado</th>
                                            <th colspan="col">Timbrado</th>
                                            <th colspan="col">Fin de mes</th>
                                            <th colspan="col">Septimo día</th>
                                            <th colspan="col">Dias desface</th>
                                            <th colspan="col">Fecha poliza</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="periodo_generado.periodo_array.length > 0; else pintaEjercicio">
                                        <tr *ngFor="let periodo of periodo_generado.periodo_array">
                                            <td style="vertical-align: middle;">{{periodo.mes_completo}}</td>
                                            <td style="vertical-align: middle;" *ngIf="periodo.tipo == 1 && periodo.band">
                                                <mat-form-field id="fecha_ant" style="max-width: 100px;">
                                                    <input matInput [(ngModel)]="periodo.fecha_inicial" [matDatepicker]="pickertres" placeholder="MM/DD/YYYY" (dateInput)="generar(3,$event.value)">
                                                    <mat-datepicker-toggle matSuffix [for]="pickertres"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickertres></mat-datepicker>
                                                  </mat-form-field>
                                            </td>
                                            <td style="vertical-align: middle;" *ngIf="periodo.tipo == 1 && !periodo.band">{{periodo.fecha_inicial}}</td>
                                            <td style="vertical-align: middle;" *ngIf="periodo.tipo != 1">{{periodo.fecha_inicial}}</td>
                                            <td style="vertical-align: middle;">{{periodo.fecha_final}}</td>
                                            <td style="vertical-align: middle;">
                                                <div class="custom-control custom-switch">
                                                    <input [(ngModel)]="periodo.actual" name="activo" type="checkbox" class="custom-control-input" id="actual{{periodo.id_periodo}}" (click)="btn_actual(periodo.id_periodo)">
                                                    <label class="custom-control-label" for="actual{{periodo.id_periodo}}"></label>
                                                </div>
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <div class="custom-control custom-switch">
                                                    <input [(ngModel)]="periodo.cerrado" name="activo" type="checkbox" class="custom-control-input" id="cerrado{{periodo.cerrado}}" (click)="btn_actual(periodo.id_periodo)" disabled>
                                                    <label class="custom-control-label" for="cerrado{{periodo.id_periodo}}"></label>
                                                </div>
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <div class="custom-control custom-switch">
                                                    <input [(ngModel)]="periodo.timbrado" name="activo" type="checkbox" class="custom-control-input" id="timbrado{{periodo.id_periodo}}">
                                                    <label class="custom-control-label" for="timbrado{{periodo.id_periodo}}"></label>
                                                </div>
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <div class="custom-control custom-switch">
                                                    <input [(ngModel)]="periodo.fin_de_mes" name="activo" type="checkbox" class="custom-control-input" id="fin{{periodo.cerrado}}"  disabled>
                                                    <label class="custom-control-label" for="fin{{periodo.id_periodo}}"></label>
                                                </div>
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <input type="number" [(ngModel)]="periodo.septimo" class="form-control" style="max-width: 100px;" min="0" max="30">
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <input type="number" [(ngModel)]="periodo.dias_desface" class="form-control" style="max-width: 100px;" min="0" max="30">
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <mat-form-field id="fecha_ant" style="max-width: 100px;">
                                                    <input matInput [(ngModel)]="periodo.fecha_poliza" [matDatepicker]="pickertres" placeholder="MM/DD/YYYY">
                                                    <mat-datepicker-toggle matSuffix [for]="pickertres"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickertres></mat-datepicker>
                                                  </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <ng-template #pintaEjercicio>
                                        <tbody>
                                            <tr class="text-center">
                                                <td colspan="9">Aún no se ha generado el periodo</td>
                                            </tr>
                                        </tbody>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="importar" role="tabpanel" aria-labelledby="profile-tab">
                            <label for="adjuntar" class="mt-2 text-warning">Selecciona el archivo excel a importar</label>
                            <input type="file" name="" id="adjuntar" class="form-control">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="cerrarModal()">Cerrar</button>
        <button *ngIf="tipo_modal == 1" type="button" class="btn btn-success" id="guardar" (click)="guardarPeriodo()">Guardar</button>
        <button *ngIf="tipo_modal == 2" type="button" class="btn btn-success" id="editar" (click)="modificarPeriodo()">Editar</button>
        <!-- <button type="button" class="btn btn-success" (click)="modificarUsuario()" id="editar" >Editar</button> -->
    </div>
</ng-template>

